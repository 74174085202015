import {fork, put, take, call} from 'redux-saga/effects';
import {FormikErrors} from 'formik';
import get from 'lodash/get';

import {http} from 'services/http';

import {
    GET_USER_INFO,
    getUserInfo,
    RESEND_EMAIL,
    resendEmail,
    SET_LANGUAGE,
    setLanguage,
    GET_FILE_URL,
    getFileUrl,
    UPDATE_PASSWORD,
    updateCurrentPassword,
    UPDATE_GENERAL_INFORMATION,
    updateGeneralInformation,
    UPDATE_USER_CUSTOM_ATTRIBUTE,
    updateUserCustomAttribute,
    UPDATE_NICKNAME,
    updateUserNickname,
    UPDATE_NICKNAME_ANONYMOUS,
    updateUserNicknameAnonymous,
    UPDATE_ANONYMOUS_WITHOUT_ACCOUNT,
    updateUserAnonymousWithoutAccount,
    TWO_FACTOR_STATUS_SWITCH,
    changeTwoFactorStatus,
    GET_TWO_FACTOR_QR_CODE,
    getTwoFactorQrCode,
    SET_STANDARD_AVATAR,
    setStandardAvatar,
    UPLOAD_AVATAR,
    uploadAvatar,
    DELETE_AVATAR,
    deleteAvatar,
    GET_AVATAR,
    getUserAvatar,
    DELETE_USER_ACCOUNT,
    deleteUserAccount,
    SEND_EMAIL_UPDATE_REQUEST,
    sendEmailUpdateRequest,
    EMAIL_UPDATE_REQUEST,
    emailUpdateRequest,
    UPDATE_DEFAULT_ROLE,
    updateDefaultRole,
    USER_ACCOUNT_EXPORT_LIST,
    getUserAccountExportList,
    USER_ACCOUNT_EXPORT_GENERATE,
    userAccountExportGenerate,
    USER_ACCOUNT_EXPORT_DOWNLOAD,
    downloadUserAccountExport,
} from 'appRedux/actions/profile';
import {
    MainProfileResponseTypes,
    StandardAvatarRequestTypes,
    SetLanguageRequestTypes,
    GetFileUrlRequestTypes,
    GetFileUrlResponseTypes,
    UpdatePasswordType,
    QrCodeResponseTypes,
    GetUserRequestTypes,
    UserInformationRequestType,
    UserNicknameUpdateRequestType,
    UserNicknameUpdateAnonymousRequestType,
    UserAnonymousWithoutAccountRequestType,
    UpdateTwoFactorStatusRequestType,
    UploadAvatarRequestTypes,
    UploadAvatarResponseTypes,
    DeleteAvatarRequestTypes,
    DeleteUserAccountRequestTypes,
    DeleteUserAccountResponseTypes,
    ResendEmailRequestTypes,
    UpdateEmailRequestCreateRequestType,
    UpdateEmailRequestType,
    UpdateDefaultRoleRequestType,
    UserAccountExportGenerationRequestTypes,
    UserAccountExportListRequestTypes,
    UserAccountExportResponseTypes,
    UserAccountExportDownloadRequestTypes,
    UserAccountExportDownloadResponseTypes,
    UserCustomAttributeUpdateRequestType,
} from 'appRedux/actions/profile/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, ALERT_SUCCESS_MESSAGE_SEND_AGAIN} from 'config/index';
import {ERROR_MESSAGE_WRONG_LOGO_TYPE, ERROR_MESSAGE_WRONG_LOGO_SIZE} from 'config/errors';

function* watchGetUserInfo() {
    while (true) {
        const {
            payload: {callback},
        }: IActionType<GetUserRequestTypes> = yield take(GET_USER_INFO.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/info`, {
                method: 'GET',
            });
            if (data.id) {
                yield put(getUserInfo.success(data));
                const {roles, languageId, organizationMultiRoleType, typeMultiRole} = data;
                callback && callback(roles, organizationMultiRoleType, languageId, typeMultiRole);
            } else {
                yield put(getUserInfo.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getUserInfo.error({message: String(e)}));
        }
    }
}

function* watchResendEmail() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<ResendEmailRequestTypes> = yield take(RESEND_EMAIL.REQUEST);
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const data = yield call(http, `resend/email`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(resendEmail.success());
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS, ALERT_SUCCESS_MESSAGE_SEND_AGAIN);
            } else {
                yield put(resendEmail.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(supportRequestStep.error(e.toString()));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchSetLanguage() {
    while (true) {
        const {
            payload: {lang, showAlert},
        }: IActionType<SetLanguageRequestTypes> = yield take(SET_LANGUAGE.REQUEST);

        try {
            const data: MainProfileResponseTypes = yield call(http, 'user/set/language', {
                method: 'POST',
                body: JSON.stringify({lang}),
            });

            yield put(setLanguage.success(data));
            showAlert && showAlert(ALERT_TYPE_SUCCESS);
        } catch (e) {
            yield put(setLanguage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetFileUrl() {
    while (true) {
        const {
            payload: {uuid, callback},
        }: IActionType<GetFileUrlRequestTypes> = yield take(GET_FILE_URL.REQUEST);
        try {
            const data: GetFileUrlResponseTypes = yield call(http, `document/${uuid}/url`, {
                method: 'GET',
            });
            if (data.fileLink) {
                yield put(getFileUrl.success(data));
                callback(data.fileLink);
            } else {
                yield put(getFileUrl.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(getFileUrl.error(e.toString()));
        }
    }
}

function* watchGeneralInformationUpdating() {
    while (true) {
        const {
            payload: {setErrors, showAlert, ...values},
        }: IActionType<UserInformationRequestType> = yield take(UPDATE_GENERAL_INFORMATION.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/general`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.email) {
                yield put(updateGeneralInformation.success(data));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateGeneralInformation.error(data.errors));
                const errors: FormikErrors<UserInformationRequestType> = get(data, 'errors', {});
                setErrors && setErrors(errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateGeneralInformation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCustomAttributeUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, ...values},
        }: IActionType<UserCustomAttributeUpdateRequestType> = yield take(UPDATE_USER_CUSTOM_ATTRIBUTE.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/attribute/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.email) {
                yield put(updateUserCustomAttribute.success(data));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateUserCustomAttribute.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateUserCustomAttribute.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserNicknameUpdating() {
    while (true) {
        const {
            payload: {setErrors, showAlert, ...values},
        }: IActionType<UserNicknameUpdateRequestType> = yield take(UPDATE_NICKNAME.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/nickname`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.email) {
                yield put(updateUserNickname.success(data));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateUserNickname.error(data.errors));
                const errors: FormikErrors<UserNicknameUpdateRequestType> = get(data, 'errors', {});
                setErrors && setErrors(errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateUserNickname.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserNicknameUpdatingAnonymous() {
    while (true) {
        const {
            payload: {setErrors, userId, showAlert, callback, ...values},
        }: IActionType<UserNicknameUpdateAnonymousRequestType> = yield take(UPDATE_NICKNAME_ANONYMOUS.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/${userId}/anonymous`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.email) {
                yield put(updateUserNicknameAnonymous.success(data));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(updateUserNicknameAnonymous.error(data.errors));
                const errors: FormikErrors<UserNicknameUpdateRequestType> = get(data, 'errors', {});
                setErrors && setErrors(errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateUserNicknameAnonymous.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserDefaultRoleUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, callbackSwitcher, ...values},
        }: IActionType<UpdateDefaultRoleRequestType> = yield take(UPDATE_DEFAULT_ROLE.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/multirole`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.id) {
                yield put(updateDefaultRole.success(data));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback(data.roles, data.organizationMultiRoleType, data.typeMultiRole);
                callbackSwitcher && callbackSwitcher();
            } else if (data.errors) {
                yield put(updateDefaultRole.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateDefaultRole.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserAnonymousWithoutAccountSetting() {
    while (true) {
        const {
            payload: {showAlert, callback},
        }: IActionType<UserAnonymousWithoutAccountRequestType> = yield take(UPDATE_ANONYMOUS_WITHOUT_ACCOUNT.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/without/account`, {
                method: 'POST',
            });
            if (data.email) {
                yield put(updateUserAnonymousWithoutAccount.success(data));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(updateUserAnonymousWithoutAccount.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateUserAnonymousWithoutAccount.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPasswordUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, ...values},
        }: IActionType<UpdatePasswordType> = yield take(UPDATE_PASSWORD.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/password`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.id) {
                yield put(updateCurrentPassword.success(data));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateCurrentPassword.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, typeof data.errors === 'string' ? data.errors : '');
            }
        } catch (e) {
            yield put(updateCurrentPassword.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchTwoFactorStatusChanging() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<UpdateTwoFactorStatusRequestType> = yield take(TWO_FACTOR_STATUS_SWITCH.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/two-factor`, {
                method: 'POST',
            });
            if (data.id) {
                yield put(changeTwoFactorStatus.success(data));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(changeTwoFactorStatus.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(changeTwoFactorStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchQrCodeGetting() {
    while (true) {
        yield take(GET_TWO_FACTOR_QR_CODE.REQUEST);
        try {
            const data: QrCodeResponseTypes = yield call(http, `user/qr-code`, {
                method: 'POST',
            });
            if (data.qrCodeContent) {
                yield put(getTwoFactorQrCode.success(data));
            } else {
                yield put(getTwoFactorQrCode.error(data.errors));
            }
        } catch (e) {
            yield put(getTwoFactorQrCode.error({message: String(e)}));
        }
    }
}

function* watchStandardAvatarSetting() {
    while (true) {
        const {
            payload: {callback, showAlert, ...values},
        }: IActionType<StandardAvatarRequestTypes> = yield take(SET_STANDARD_AVATAR.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/avatar/standard`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.id) {
                yield put(setStandardAvatar.success(data));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(setStandardAvatar.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(setStandardAvatar.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetUserAvatar() {
    while (true) {
        yield take(GET_AVATAR.REQUEST);
        try {
            const data: UploadAvatarResponseTypes = yield call(http, `user/avatar`, {
                method: 'GET',
            });
            if (data.avatarLink) {
                yield put(getUserAvatar.success(data));
            } else {
                yield put(getUserAvatar.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getUserAvatar.error({message: String(e)}));
        }
    }
}

function* watchUploadAvatar() {
    while (true) {
        const {
            payload: {file, setErrors, callback, showAlert},
        }: IActionType<UploadAvatarRequestTypes> = yield take(UPLOAD_AVATAR.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file);
            const data: UploadAvatarResponseTypes = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `user/avatar/upload`,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            if (data.avatarLink) {
                yield put(uploadAvatar.success(data));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors === ERROR_MESSAGE_WRONG_LOGO_SIZE) {
                yield put(uploadAvatar.error({message: 'messages.error.wrongDocumentSizeMessage'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, 'messages.error.wrongDocumentSizeMessage');
            } else if (data.errors === ERROR_MESSAGE_WRONG_LOGO_TYPE) {
                yield put(uploadAvatar.error({message: 'messages.error.wrongLogoTypeMessage'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, 'messages.error.wrongLogoTypeMessage');
            } else {
                setErrors && setErrors(data.errors);
                yield put(uploadAvatar.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(uploadAvatar.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDeleteAvatar() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<DeleteAvatarRequestTypes> = yield take(DELETE_AVATAR.REQUEST);
        try {
            const data: MainProfileResponseTypes = yield call(http, `user/avatar/delete`, {
                method: 'GET',
            });
            if (data.id) {
                yield put(deleteAvatar.success(data));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteAvatar.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteAvatar.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDeleteUserAccount() {
    while (true) {
        const {
            payload: {callback, showAlert, ...values},
        }: IActionType<DeleteUserAccountRequestTypes> = yield take(DELETE_USER_ACCOUNT.REQUEST);
        try {
            const data: DeleteUserAccountResponseTypes = yield call(http, `user/account/delete`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(deleteUserAccount.success());
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteUserAccount.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, String(data.errors));
            }
        } catch (e) {
            yield put(deleteUserAccount.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchEmailUpdateRequestCreation() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<UpdateEmailRequestCreateRequestType> = yield take(SEND_EMAIL_UPDATE_REQUEST.REQUEST);
        try {
            const data: DeleteUserAccountResponseTypes = yield call(http, `update/email/request`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(sendEmailUpdateRequest.success());
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS, 'messages.success.emailUpdateRequestSuccessfullySend');
            } else {
                yield put(sendEmailUpdateRequest.error({message: String(data.errors)}));
                showAlert && showAlert(ALERT_TYPE_ERROR, String(data.errors));
            }
        } catch (e) {
            yield put(sendEmailUpdateRequest.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchEmailUpdateRequest() {
    while (true) {
        const {
            payload: {callback, showAlert, ...values},
        }: IActionType<UpdateEmailRequestType> = yield take(EMAIL_UPDATE_REQUEST.REQUEST);
        try {
            const data: DeleteUserAccountResponseTypes = yield call(http, `update/user/email`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(emailUpdateRequest.success());
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS, 'messages.success.emailSuccessfullyUpdated');
            } else {
                yield put(emailUpdateRequest.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, String(data.errors));
            }
        } catch (e) {
            yield put(emailUpdateRequest.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserAccountExportsListRequest() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<UserAccountExportListRequestTypes> = yield take(USER_ACCOUNT_EXPORT_LIST.REQUEST);
        try {
            const data: UserAccountExportResponseTypes = yield call(http, `user/export/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getUserAccountExportList.success(data.result));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(getUserAccountExportList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getUserAccountExportList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserAccountExportGenerationRequest() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<UserAccountExportGenerationRequestTypes> = yield take(USER_ACCOUNT_EXPORT_GENERATE.REQUEST);
        try {
            const data: UserAccountExportResponseTypes = yield call(http, `user/generate/export`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(userAccountExportGenerate.success(data.result));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS, 'messages.success.userAccountExportCreated');
            } else {
                yield put(userAccountExportGenerate.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(userAccountExportGenerate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserAccountExportDownloadingRequest() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<UserAccountExportDownloadRequestTypes> = yield take(USER_ACCOUNT_EXPORT_DOWNLOAD.REQUEST);
        try {
            const data: UserAccountExportDownloadResponseTypes = yield call(http, `user/export/${uuid}/download`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(downloadUserAccountExport.success());
                callback && callback(data.fileLink);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(downloadUserAccountExport.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, 'messages.error.somethingWentWrong');
            }
        } catch (e) {
            yield put(downloadUserAccountExport.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGetUserInfo),
    fork(watchResendEmail),
    fork(watchSetLanguage),
    fork(watchGetFileUrl),
    fork(watchGeneralInformationUpdating),
    fork(watchCustomAttributeUpdating),
    fork(watchUserNicknameUpdating),
    fork(watchUserNicknameUpdatingAnonymous),
    fork(watchUserDefaultRoleUpdating),
    fork(watchUserAnonymousWithoutAccountSetting),
    fork(watchPasswordUpdating),
    fork(watchTwoFactorStatusChanging),
    fork(watchQrCodeGetting),
    fork(watchStandardAvatarSetting),
    fork(watchGetUserAvatar),
    fork(watchUploadAvatar),
    fork(watchDeleteAvatar),
    fork(watchDeleteUserAccount),
    fork(watchEmailUpdateRequestCreation),
    fork(watchEmailUpdateRequest),
    fork(watchUserAccountExportsListRequest),
    fork(watchUserAccountExportGenerationRequest),
    fork(watchUserAccountExportDownloadingRequest),
];
