import * as yup from 'yup';

import {CustomAttributeOptionTypes} from 'appRedux/actions/customAttributes/types';

export const validationSchema = () =>
    yup.object({
        title: yup.string().required('messages.validation.isRequired'),
        description: yup.string().nullable(),
    });

export const validationOptionSchema = (options: CustomAttributeOptionTypes[]) =>
    yup.object({
        title: yup
            .string()
            .required('messages.validation.isRequired')
            .test('optionTitle', '', function (value) {
                if (options.some(option => option.title === value)) {
                    return this.createError({
                        message: `orguser.forms.formField.validation.optionsRepeatValidation`,
                    });
                }
                return true;
            }),
    });

export const validationTranslationSchema = () =>
    yup.object({
        translation: yup.string().required('messages.validation.isRequired'),
    });

export const validationAttachmentSchema = () =>
    yup.object({
        uuid: yup.string().required('messages.validation.isRequired'),
    });
