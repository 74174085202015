import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import {REQUEST_CASE_CANCEL} from 'appRedux/actions/requestCase';
import {RequesterCaseCancelingFormTypes} from 'appRedux/actions/requestCase/types';

import {AlertContext} from 'contexts/alert/context';

import {validationCancelReasonSchema} from 'components/Forms/RequesterCase/validation';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ClientTextAreaField from 'components/ClientScreenComponents/ClientTextAreaField';

interface ComponentType {
    caseId: number;
    closeModal: () => void;
}

const CancelRequesterCaseForm: FC<ComponentType> = ({caseId, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const cancelRequesterCase = useCallback(
        data => dispatch({type: REQUEST_CASE_CANCEL.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Formik
            initialValues={{
                cancelReason: '',
            }}
            validationSchema={() => validationCancelReasonSchema(t)}
            onSubmit={(values: RequesterCaseCancelingFormTypes) => {
                cancelRequesterCase({
                    id: caseId,
                    ...values,
                    showAlert,
                    callback: closeModal,
                });
            }}
        >
            {(formik: FormikProps<RequesterCaseCancelingFormTypes>) => {
                return (
                    <Form>
                        <FormControl fullWidth>
                            <Field
                                required
                                fullWidth
                                name={'cancelReason'}
                                component={ClientTextAreaField}
                                multiline
                                rows={5}
                            />
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CancelRequesterCaseForm;
