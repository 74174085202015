import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import {GridColDef} from '@mui/x-data-grid';

import {GET_CUSTOM_ATTRIBUTES_LIST} from 'appRedux/actions/customAttributes';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import AddCustomAttributeButton from 'pages/admin/customAttributes/AddCustomAttributeButton';
import AddCustomAttributeDropdown from 'pages/admin/customAttributes/AddCustomAttributeDropdown';
import getAttributesColumns from 'pages/admin/customAttributes/getAttributesColumns';

const CustomAttributesList: FC = () => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {
        isSuperAdminPage,
        refreshPageInformation,
        setPageTitle,
        setTopBarTitle,
        setBackLink,
        setBackTab,
        setBackLinkLabel,
    } = useContext(RouteContext);

    const {
        instance: {customAttributes},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getCustomAttributesList = useCallback(
        data => dispatch({type: GET_CUSTOM_ATTRIBUTES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    useEffect(() => {
        refreshPageInformation();
        getCustomAttributesList({
            showAlert,
        });
    }, []);

    const columns: GridColDef[] | null = getAttributesColumns();

    return (
        <Box>
            {isSuperAdminPage ? <AddCustomAttributeButton /> : <AddCustomAttributeDropdown />}
            <DataGridWrapper
                itemsList={customAttributes}
                itemsTotalNumber={customAttributes ? customAttributes.length : 0}
            >
                {customAttributes && columns && (
                    <CustomDataGrid
                        rows={customAttributes}
                        columns={columns}
                        getRowId={row => row.uuid}
                        checkboxSelection={false}
                        enablePaging
                    />
                )}
            </DataGridWrapper>
        </Box>
    );
};

export default CustomAttributesList;
