import {requestTypeCreator} from 'helpers/index';

import {
    CustomAttributesListRequestTypes,
    CustomAttributesItemTypes,
    CustomAttributeCreateRequestTypes,
    CustomAttributeUpdateRequestTypes,
    CustomAttributeDeletingRequestTypes,
    CustomAttributeTranslationRequestTypes,
    CustomAttributeOptionCreateRequestTypes,
    CustomAttributeOptionUpdateRequestTypes,
    CustomAttributeOptionDeleteRequestTypes,
    CustomAttributesAttachRequestTypes,
} from 'appRedux/actions/customAttributes/types';

export const GET_CUSTOM_ATTRIBUTES_LIST = requestTypeCreator('GET_CUSTOM_ATTRIBUTES_LIST');
export const GET_GLOBAL_ATTRIBUTES_LIST = requestTypeCreator('GET_GLOBAL_ATTRIBUTES_LIST');
export const CREATE_CUSTOM_ATTRIBUTE = requestTypeCreator('CREATE_CUSTOM_ATTRIBUTE');
export const ATTACH_CUSTOM_ATTRIBUTE = requestTypeCreator('ATTACH_CUSTOM_ATTRIBUTE');
export const UPDATE_CUSTOM_ATTRIBUTE = requestTypeCreator('UPDATE_CUSTOM_ATTRIBUTE');
export const DELETE_CUSTOM_ATTRIBUTE = requestTypeCreator('DELETE_CUSTOM_ATTRIBUTE');
export const TRANSLATE_CUSTOM_ATTRIBUTE = requestTypeCreator('TRANSLATE_CUSTOM_ATTRIBUTE');

export const CREATE_CUSTOM_ATTRIBUTE_OPTION = requestTypeCreator('CREATE_CUSTOM_ATTRIBUTE_OPTION');
export const UPDATE_CUSTOM_ATTRIBUTE_OPTION = requestTypeCreator('UPDATE_CUSTOM_ATTRIBUTE_OPTION');
export const DELETE_CUSTOM_ATTRIBUTE_OPTION = requestTypeCreator('DELETE_CUSTOM_ATTRIBUTE_OPTION');

export const getCustomAttributesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_CUSTOM_ATTRIBUTES_LIST.ERROR,
    }),
    request: (payload: CustomAttributesListRequestTypes): IActionType<CustomAttributesListRequestTypes> => ({
        payload,
        type: GET_CUSTOM_ATTRIBUTES_LIST.REQUEST,
    }),
    success: (payload: CustomAttributesItemTypes[]): IActionType<CustomAttributesItemTypes[]> => ({
        payload,
        type: GET_CUSTOM_ATTRIBUTES_LIST.SUCCESS,
    }),
};

export const getGlobalAttributesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_GLOBAL_ATTRIBUTES_LIST.ERROR,
    }),
    request: (payload: CustomAttributesListRequestTypes): IActionType<CustomAttributesListRequestTypes> => ({
        payload,
        type: GET_GLOBAL_ATTRIBUTES_LIST.REQUEST,
    }),
    success: (payload: CustomAttributesItemTypes[]): IActionType<CustomAttributesItemTypes[]> => ({
        payload,
        type: GET_GLOBAL_ATTRIBUTES_LIST.SUCCESS,
    }),
};

export const attachCustomAttribute = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ATTACH_CUSTOM_ATTRIBUTE.ERROR,
    }),
    request: (payload: CustomAttributesAttachRequestTypes): IActionType<CustomAttributesAttachRequestTypes> => ({
        payload,
        type: ATTACH_CUSTOM_ATTRIBUTE.REQUEST,
    }),
    success: (payload: CustomAttributesItemTypes[]): IActionType<CustomAttributesItemTypes[]> => ({
        payload,
        type: ATTACH_CUSTOM_ATTRIBUTE.SUCCESS,
    }),
};

export const createCustomAttribute = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_CUSTOM_ATTRIBUTE.ERROR,
    }),
    request: (payload: CustomAttributeCreateRequestTypes): IActionType<CustomAttributeCreateRequestTypes> => ({
        payload,
        type: CREATE_CUSTOM_ATTRIBUTE.REQUEST,
    }),
    success: (payload: CustomAttributesItemTypes[]): IActionType<CustomAttributesItemTypes[]> => ({
        payload,
        type: CREATE_CUSTOM_ATTRIBUTE.SUCCESS,
    }),
};

export const updateCustomAttribute = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_CUSTOM_ATTRIBUTE.ERROR,
    }),
    request: (payload: CustomAttributeUpdateRequestTypes): IActionType<CustomAttributeUpdateRequestTypes> => ({
        payload,
        type: UPDATE_CUSTOM_ATTRIBUTE.REQUEST,
    }),
    success: (payload: CustomAttributesItemTypes[]): IActionType<CustomAttributesItemTypes[]> => ({
        payload,
        type: UPDATE_CUSTOM_ATTRIBUTE.SUCCESS,
    }),
};

export const deleteCustomAttribute = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_CUSTOM_ATTRIBUTE.ERROR,
    }),
    request: (payload: CustomAttributeDeletingRequestTypes): IActionType<CustomAttributeDeletingRequestTypes> => ({
        payload,
        type: DELETE_CUSTOM_ATTRIBUTE.REQUEST,
    }),
    success: (payload: CustomAttributesItemTypes[]): IActionType<CustomAttributesItemTypes[]> => ({
        payload,
        type: DELETE_CUSTOM_ATTRIBUTE.SUCCESS,
    }),
};

export const translateCustomAttribute = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TRANSLATE_CUSTOM_ATTRIBUTE.ERROR,
    }),
    request: (
        payload: CustomAttributeTranslationRequestTypes,
    ): IActionType<CustomAttributeTranslationRequestTypes> => ({
        payload,
        type: TRANSLATE_CUSTOM_ATTRIBUTE.REQUEST,
    }),
    success: (payload: CustomAttributesItemTypes[]): IActionType<CustomAttributesItemTypes[]> => ({
        payload,
        type: TRANSLATE_CUSTOM_ATTRIBUTE.SUCCESS,
    }),
};

export const createCustomAttributeOption = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_CUSTOM_ATTRIBUTE_OPTION.ERROR,
    }),
    request: (
        payload: CustomAttributeOptionCreateRequestTypes,
    ): IActionType<CustomAttributeOptionCreateRequestTypes> => ({
        payload,
        type: CREATE_CUSTOM_ATTRIBUTE_OPTION.REQUEST,
    }),
    success: (payload: CustomAttributesItemTypes[]): IActionType<CustomAttributesItemTypes[]> => ({
        payload,
        type: CREATE_CUSTOM_ATTRIBUTE_OPTION.SUCCESS,
    }),
};

export const updateCustomAttributeOption = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_CUSTOM_ATTRIBUTE_OPTION.ERROR,
    }),
    request: (
        payload: CustomAttributeOptionUpdateRequestTypes,
    ): IActionType<CustomAttributeOptionUpdateRequestTypes> => ({
        payload,
        type: UPDATE_CUSTOM_ATTRIBUTE_OPTION.REQUEST,
    }),
    success: (payload: CustomAttributesItemTypes[]): IActionType<CustomAttributesItemTypes[]> => ({
        payload,
        type: UPDATE_CUSTOM_ATTRIBUTE_OPTION.SUCCESS,
    }),
};

export const deleteCustomAttributeOption = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_CUSTOM_ATTRIBUTE_OPTION.ERROR,
    }),
    request: (
        payload: CustomAttributeOptionDeleteRequestTypes,
    ): IActionType<CustomAttributeOptionDeleteRequestTypes> => ({
        payload,
        type: DELETE_CUSTOM_ATTRIBUTE_OPTION.REQUEST,
    }),
    success: (payload: CustomAttributesItemTypes[]): IActionType<CustomAttributesItemTypes[]> => ({
        payload,
        type: DELETE_CUSTOM_ATTRIBUTE_OPTION.SUCCESS,
    }),
};
