import * as yup from 'yup';

import {getUsernameLengthErrorMessage} from 'helpers/validation';

import {MAXIMAL_FIRSTNAME_LENGTH, MAXIMAL_LASTNAME_LENGTH} from 'config/index';

export const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
};

export const validationSchema = (t: (trans: string) => string) =>
    yup.object({
        firstName: yup
            .string()
            .required(t('messages.validation.firstNameRequired'))
            .max(
                MAXIMAL_FIRSTNAME_LENGTH,
                getUsernameLengthErrorMessage(t, 'messages.validation.firstNameMaxLength', MAXIMAL_FIRSTNAME_LENGTH),
            ),
        lastName: yup
            .string()
            .required(t('messages.validation.lastNameRequired'))
            .max(
                MAXIMAL_LASTNAME_LENGTH,
                getUsernameLengthErrorMessage(t, 'lastNameMaxLength', MAXIMAL_LASTNAME_LENGTH),
            ),
        email: yup.string().email().required(t('messages.validation.emailRequired')),
    });

export const validationAgentReassignSchema = (t: (trans: string) => string) =>
    yup.object({
        agent: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationRelateSchema = (t: (trans: string) => string) =>
    yup.object({
        admin: yup.string().required(t('messages.validation.isRequired')),
    });

export const validationRelateOtherOrganizationSchema = () =>
    yup.object({
        user: yup.number().required('messages.validation.isRequired'),
        organization: yup.number().required('messages.validation.isRequired'),
    });

export const validateCustomAttribute = () =>
    yup.object({
        value: yup.string().required('messages.validation.isRequired'),
    });
