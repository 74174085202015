import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import RelatedTasksButton from 'pages/agent/requesterPage/formInformation/relatedTasks/RelatedTasksButton';
import DeletingReasonButton from 'pages/agent/requesterPage/formInformation/status/DeletingReasonButton';

import {STATUS_DELETED} from 'config/index';

const AdditionalStatusInformation: FC = () => {
    const {
        requestCase: {
            currentCase: {tasks, status, cancelReason, canceledAt},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            {tasks && tasks.length > 0 && <RelatedTasksButton />}
            {status === STATUS_DELETED && cancelReason && canceledAt && (
                <DeletingReasonButton cancelReason={cancelReason} canceledAt={canceledAt} />
            )}
        </Box>
    );
};

export default AdditionalStatusInformation;
