import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';

import {UPDATE_USER_CUSTOM_ATTRIBUTE} from 'appRedux/actions/profile';
import {UserCustomAttributeUpdateFormType} from 'appRedux/actions/profile/types';
import {CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {
    CUSTOM_ATTRIBUTE_TYPE_TEXTAREA,
    CUSTOM_ATTRIBUTE_TYPE_DROPDOWN,
    getCustomAttributeTranslationKeyword,
    getCustomAttributeTranslatedLabel,
} from 'components/Forms/CustomAttributes/helper';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import {validateCustomAttribute} from 'components/Forms/SettingsForms/UserHasCustomAttributeForm/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';
import UserHasCustomAttributeDropdownForm from 'components/Forms/SettingsForms/UserHasCustomAttributeForm/UserHasCustomAttributeDropdownForm';

import {DEFAULT_VALUE} from 'config/index';

interface ComponentType {
    attribute: CustomAttributesItemTypes;
}

const UserHasCustomAttributeForm: FC<ComponentType> = ({attribute}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, uuid, title, type, parentId} = attribute;

    const updateUserCustomAttribute = useCallback(
        data => dispatch({type: UPDATE_USER_CUSTOM_ATTRIBUTE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: UserCustomAttributeUpdateFormType) => {
        updateUserCustomAttribute({
            uuid,
            ...values,
            showAlert,
        });
    };

    const titleKeyword = parentId
        ? getCustomAttributeTranslationKeyword(parentId, 'title')
        : getCustomAttributeTranslationKeyword(id, 'title');
    const descriptionKeyword = parentId
        ? getCustomAttributeTranslationKeyword(parentId, 'description')
        : getCustomAttributeTranslationKeyword(id, 'description');

    const translatedLabel = profile
        ? getCustomAttributeTranslatedLabel(attribute, titleKeyword, profile.languageId, title)
        : title;
    const translatedDescription = profile
        ? getCustomAttributeTranslatedLabel(attribute, descriptionKeyword, profile.languageId, title)
        : title;

    const currentUserValue = profile ? profile.customAttributes.find(item => item.attribute === id) : null;

    if (type === CUSTOM_ATTRIBUTE_TYPE_DROPDOWN) {
        return (
            <UserHasCustomAttributeDropdownForm
                initialValues={{
                    value: DEFAULT_VALUE,
                    option: currentUserValue ? currentUserValue.option : null,
                }}
                translatedLabel={translatedLabel}
                translatedDescription={translatedDescription}
                onSubmitClicked={onSubmitClicked}
                customAttribute={attribute}
            />
        );
    }
    return (
        <Box>
            <Box sx={{mb: 1}}>
                <Formik
                    initialValues={{
                        value: currentUserValue ? currentUserValue.value : '',
                        option: null,
                    }}
                    validationSchema={validateCustomAttribute}
                    onSubmit={onSubmitClicked}
                >
                    {(formik: FormikProps<any>) => {
                        return (
                            <Form>
                                {type === CUSTOM_ATTRIBUTE_TYPE_TEXTAREA ? (
                                    <MdxEditorSmallField
                                        name="value"
                                        label={translatedLabel}
                                        value={currentUserValue ? currentUserValue.value : ''}
                                        customButtons={[]}
                                        customOnBlur={formik.submitForm}
                                        description={translatedDescription}
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mb: 1,
                                        }}
                                    >
                                        <Box sx={{width: '95%'}}>
                                            <Field
                                                name="value"
                                                label={translatedLabel}
                                                type="text"
                                                component={FormikTextInput}
                                                onBlur={formik.submitForm}
                                            />
                                        </Box>
                                        {translatedDescription && (
                                            <DescriptionPopover
                                                text={translatedDescription ? translatedDescription : ''}
                                            />
                                        )}
                                    </Box>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};

export default UserHasCustomAttributeForm;
