import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {TRANSLATE_CUSTOM_ATTRIBUTE} from 'appRedux/actions/customAttributes';
import {
    CustomAttributeTranslationFormTypes,
    CustomAttributeTranslationTypes,
} from 'appRedux/actions/customAttributes/types';

import {AlertContext} from 'contexts/alert/context';

import {validationTranslationSchema} from 'components/Forms/CustomAttributes/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';

import {theme} from 'config/theme';

interface ComponentType {
    uuid: string;
    title: string;
    keyword: string;
    language: number;
    currentTranslation?: CustomAttributeTranslationTypes;
    defaultValue: string;
    isTextArea?: boolean;
}

const CustomAttributeTranslationForm: FC<ComponentType> = ({
    uuid,
    title,
    keyword,
    language,
    currentTranslation,
    defaultValue,
    isTextArea,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const translateCustomAttribute = useCallback(
        data => dispatch({type: TRANSLATE_CUSTOM_ATTRIBUTE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: CustomAttributeTranslationFormTypes) => {
        translateCustomAttribute({
            uuid,
            showAlert,
            ...values,
        });
    };

    return (
        <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
                backgroundColor: theme.palette.warning.contrastText,
                borderRadius: 3,
                p: 1,
                pl: 2,
                pr: 2,
                mt: 1,
                mb: 1,
            }}
        >
            <Grid item sm={2}>
                <Typography sx={{fontWeight: 700}}>{title}</Typography>
            </Grid>
            <Grid item sm={3}>
                <Typography>{defaultValue}</Typography>
            </Grid>
            <Grid item sm={7}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        language,
                        keyword,
                        translation: currentTranslation ? currentTranslation.translation : '',
                    }}
                    validationSchema={() => validationTranslationSchema()}
                    onSubmit={onSubmitClicked}
                >
                    {(formik: FormikProps<CustomAttributeTranslationFormTypes>) => {
                        return (
                            <Form>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    {isTextArea ? (
                                        <MdxEditorSmallField
                                            name="translation"
                                            value={currentTranslation ? currentTranslation.translation : ''}
                                            customButtons={[]}
                                            customOnBlur={formik.submitForm}
                                        />
                                    ) : (
                                        <Field
                                            required
                                            name="translation"
                                            placeholder={t('superadmin.customAttributes.addTranslation')}
                                            component={FormikTextInput}
                                            onBlur={formik.submitForm}
                                        />
                                    )}
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </Grid>
    );
};

export default CustomAttributeTranslationForm;
