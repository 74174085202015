import {requestTypeCreator} from 'helpers/index';
import {
    MainProfileResponseTypes,
    TwoFactorActivateTypes,
    StandardAvatarRequestTypes,
    SetLanguageRequestTypes,
    GetFileUrlRequestTypes,
    GetFileUrlResponseTypes,
    UserInformationRequestType,
    UserNicknameUpdateRequestType,
    UserNicknameUpdateAnonymousRequestType,
    QrCodeResponseTypes,
    UpdatePasswordType,
    UploadAvatarRequestTypes,
    UploadAvatarResponseTypes,
    DeleteAvatarRequestTypes,
    DeleteUserAccountRequestTypes,
    ResendEmailRequestTypes,
    UpdateEmailRequestCreateRequestType,
    UpdateEmailRequestType,
    UpdateDefaultRoleRequestType,
    UserAccountExportListRequestTypes,
    UserAccountExportGenerationRequestTypes,
    UserAccountExportTypes,
    UserAccountExportDownloadRequestTypes,
    UserCustomAttributeUpdateRequestType,
} from 'appRedux/actions/profile/types';

export const GET_USER_INFO = requestTypeCreator('USER_INFO', {ACTIVATE: 'ACTIVATE', CLEAR: 'CLEAR'});
export const RESEND_EMAIL = requestTypeCreator('RESEND_EMAIL');
export const SET_LANGUAGE = requestTypeCreator('SET_LANGUAGE');
export const GET_FILE_URL = requestTypeCreator('GET_FILE_URL');
export const UPDATE_GENERAL_INFORMATION = requestTypeCreator('UPDATE_GENERAL_INFORMATION');
export const UPDATE_USER_CUSTOM_ATTRIBUTE = requestTypeCreator('UPDATE_USER_CUSTOM_ATTRIBUTE');
export const UPDATE_NICKNAME = requestTypeCreator('UPDATE_NICKNAME');
export const UPDATE_NICKNAME_ANONYMOUS = requestTypeCreator('UPDATE_NICKNAME_ANONYMOUS');
export const UPDATE_DEFAULT_ROLE = requestTypeCreator('UPDATE_DEFAULT_ROLE');
export const UPDATE_ANONYMOUS_WITHOUT_ACCOUNT = requestTypeCreator('UPDATE_ANONYMOUS_WITHOUT_ACCOUNT');
export const UPDATE_PASSWORD = requestTypeCreator('UPDATE_PASSWORD');
export const PROFILE_ERROR_CLEAR = requestTypeCreator('PROFILE_ERROR_CLEAR');
export const TWO_FACTOR_STATUS_SWITCH = requestTypeCreator('TWO_FACTOR_STATUS_SWITCH');
export const GET_TWO_FACTOR_QR_CODE = requestTypeCreator('GET_TWO_FACTOR_QR_CODE');

export const SET_STANDARD_AVATAR = requestTypeCreator('SET_STANDARD_AVATAR');
export const GET_AVATAR = requestTypeCreator('GET_AVATAR');
export const UPLOAD_AVATAR = requestTypeCreator('UPLOAD_AVATAR');
export const DELETE_AVATAR = requestTypeCreator('DELETE_AVATAR');

export const DELETE_USER_ACCOUNT = requestTypeCreator('DELETE_USER_ACCOUNT');

export const SEND_EMAIL_UPDATE_REQUEST = requestTypeCreator('SEND_EMAIL_UPDATE_REQUEST');
export const EMAIL_UPDATE_REQUEST = requestTypeCreator('EMAIL_UPDATE_REQUEST');

export const USER_ACCOUNT_EXPORT_LIST = requestTypeCreator('USER_ACCOUNT_EXPORT_LIST');
export const USER_ACCOUNT_EXPORT_GENERATE = requestTypeCreator('USER_ACCOUNT_EXPORT_GENERATE');
export const USER_ACCOUNT_EXPORT_DOWNLOAD = requestTypeCreator('USER_ACCOUNT_EXPORT_DOWNLOAD');

export const getUserInfo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_USER_INFO.ERROR,
    }),
    request: () => ({
        type: GET_USER_INFO.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: GET_USER_INFO.SUCCESS,
    }),
    activate: (payload: TwoFactorActivateTypes): IActionType<TwoFactorActivateTypes> => ({
        payload,
        type: GET_USER_INFO.ACTIVATE,
    }),
    clear: () => ({
        type: GET_USER_INFO.CLEAR,
    }),
};

export const resendEmail = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: RESEND_EMAIL.ERROR,
    }),
    request: (payload: ResendEmailRequestTypes): IActionType<ResendEmailRequestTypes> => ({
        payload,
        type: RESEND_EMAIL.REQUEST,
    }),
    success: () => ({
        type: RESEND_EMAIL.SUCCESS,
    }),
};

export const setLanguage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: SET_LANGUAGE.ERROR,
    }),
    request: (payload: SetLanguageRequestTypes): IActionType<SetLanguageRequestTypes> => ({
        payload,
        type: SET_LANGUAGE.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: SET_LANGUAGE.SUCCESS,
    }),
};

export const getFileUrl = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_FILE_URL.ERROR,
    }),
    request: (payload: GetFileUrlRequestTypes): IActionType<GetFileUrlRequestTypes> => ({
        payload,
        type: GET_FILE_URL.REQUEST,
    }),
    success: (payload: GetFileUrlResponseTypes): IActionType<GetFileUrlResponseTypes> => ({
        payload,
        type: GET_FILE_URL.SUCCESS,
    }),
};

export const updateGeneralInformation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_GENERAL_INFORMATION.ERROR,
    }),
    request: (payload: UserInformationRequestType): IActionType<UserInformationRequestType> => ({
        payload,
        type: UPDATE_GENERAL_INFORMATION.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: UPDATE_GENERAL_INFORMATION.SUCCESS,
    }),
};

export const updateUserCustomAttribute = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_USER_CUSTOM_ATTRIBUTE.ERROR,
    }),
    request: (payload: UserCustomAttributeUpdateRequestType): IActionType<UserCustomAttributeUpdateRequestType> => ({
        payload,
        type: UPDATE_USER_CUSTOM_ATTRIBUTE.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: UPDATE_USER_CUSTOM_ATTRIBUTE.SUCCESS,
    }),
};

export const updateUserNickname = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_NICKNAME.ERROR,
    }),
    request: (payload: UserNicknameUpdateRequestType): IActionType<UserNicknameUpdateRequestType> => ({
        payload,
        type: UPDATE_NICKNAME.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: UPDATE_NICKNAME.SUCCESS,
    }),
};

export const updateUserNicknameAnonymous = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_NICKNAME_ANONYMOUS.ERROR,
    }),
    request: (
        payload: UserNicknameUpdateAnonymousRequestType,
    ): IActionType<UserNicknameUpdateAnonymousRequestType> => ({
        payload,
        type: UPDATE_NICKNAME_ANONYMOUS.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: UPDATE_NICKNAME_ANONYMOUS.SUCCESS,
    }),
};

export const updateDefaultRole = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_DEFAULT_ROLE.ERROR,
    }),
    request: (payload: UpdateDefaultRoleRequestType): IActionType<UpdateDefaultRoleRequestType> => ({
        payload,
        type: UPDATE_DEFAULT_ROLE.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: UPDATE_DEFAULT_ROLE.SUCCESS,
    }),
};

export const updateUserAnonymousWithoutAccount = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_ANONYMOUS_WITHOUT_ACCOUNT.ERROR,
    }),
    request: (
        payload: UserNicknameUpdateAnonymousRequestType,
    ): IActionType<UserNicknameUpdateAnonymousRequestType> => ({
        payload,
        type: UPDATE_ANONYMOUS_WITHOUT_ACCOUNT.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: UPDATE_ANONYMOUS_WITHOUT_ACCOUNT.SUCCESS,
    }),
};

export const updateCurrentPassword = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_PASSWORD.ERROR,
    }),
    request: (payload: UpdatePasswordType): IActionType<UpdatePasswordType> => ({
        payload,
        type: UPDATE_PASSWORD.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: UPDATE_PASSWORD.SUCCESS,
    }),
};

export const changeTwoFactorStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TWO_FACTOR_STATUS_SWITCH.ERROR,
    }),
    request: () => ({
        type: TWO_FACTOR_STATUS_SWITCH.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: TWO_FACTOR_STATUS_SWITCH.SUCCESS,
    }),
};

export const getTwoFactorQrCode = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_TWO_FACTOR_QR_CODE.ERROR,
    }),
    request: () => ({
        type: GET_TWO_FACTOR_QR_CODE.REQUEST,
    }),
    success: (payload: QrCodeResponseTypes): IActionType<QrCodeResponseTypes> => ({
        payload,
        type: GET_TWO_FACTOR_QR_CODE.SUCCESS,
    }),
};

export const clearProfileErrors = {
    request: () => ({
        type: PROFILE_ERROR_CLEAR.REQUEST,
    }),
};

export const setStandardAvatar = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: SET_STANDARD_AVATAR.ERROR,
    }),
    request: (payload: StandardAvatarRequestTypes): IActionType<StandardAvatarRequestTypes> => ({
        payload,
        type: SET_STANDARD_AVATAR.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: SET_STANDARD_AVATAR.SUCCESS,
    }),
};

export const getUserAvatar = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_AVATAR.ERROR,
    }),
    request: () => ({
        type: GET_AVATAR.REQUEST,
    }),
    success: (payload: UploadAvatarResponseTypes): IActionType<UploadAvatarResponseTypes> => ({
        payload,
        type: GET_AVATAR.SUCCESS,
    }),
};

export const uploadAvatar = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPLOAD_AVATAR.ERROR,
    }),
    request: (payload: UploadAvatarRequestTypes): IActionType<UploadAvatarRequestTypes> => ({
        payload,
        type: UPLOAD_AVATAR.REQUEST,
    }),
    success: (payload: UploadAvatarResponseTypes): IActionType<UploadAvatarResponseTypes> => ({
        payload,
        type: UPLOAD_AVATAR.SUCCESS,
    }),
};

export const deleteAvatar = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_AVATAR.ERROR,
    }),
    request: (payload: DeleteAvatarRequestTypes): IActionType<DeleteAvatarRequestTypes> => ({
        payload,
        type: DELETE_AVATAR.REQUEST,
    }),
    success: (payload: MainProfileResponseTypes): IActionType<MainProfileResponseTypes> => ({
        payload,
        type: DELETE_AVATAR.SUCCESS,
    }),
};

export const deleteUserAccount = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_USER_ACCOUNT.ERROR,
    }),
    request: (payload: DeleteUserAccountRequestTypes): IActionType<DeleteUserAccountRequestTypes> => ({
        payload,
        type: DELETE_USER_ACCOUNT.REQUEST,
    }),
    success: () => ({
        type: DELETE_USER_ACCOUNT.SUCCESS,
    }),
};

export const sendEmailUpdateRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: SEND_EMAIL_UPDATE_REQUEST.ERROR,
    }),
    request: (payload: UpdateEmailRequestCreateRequestType): IActionType<UpdateEmailRequestCreateRequestType> => ({
        payload,
        type: SEND_EMAIL_UPDATE_REQUEST.REQUEST,
    }),
    success: () => ({
        type: SEND_EMAIL_UPDATE_REQUEST.SUCCESS,
    }),
};

export const emailUpdateRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: EMAIL_UPDATE_REQUEST.ERROR,
    }),
    request: (payload: UpdateEmailRequestType): IActionType<UpdateEmailRequestType> => ({
        payload,
        type: EMAIL_UPDATE_REQUEST.REQUEST,
    }),
    success: () => ({
        type: EMAIL_UPDATE_REQUEST.SUCCESS,
    }),
};

export const getUserAccountExportList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: USER_ACCOUNT_EXPORT_LIST.ERROR,
    }),
    request: (payload: UserAccountExportListRequestTypes): IActionType<UserAccountExportListRequestTypes> => ({
        payload,
        type: USER_ACCOUNT_EXPORT_LIST.REQUEST,
    }),
    success: (payload: UserAccountExportTypes): IActionType<UserAccountExportTypes> => ({
        payload,
        type: USER_ACCOUNT_EXPORT_LIST.SUCCESS,
    }),
};

export const userAccountExportGenerate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: USER_ACCOUNT_EXPORT_GENERATE.ERROR,
    }),
    request: (
        payload: UserAccountExportGenerationRequestTypes,
    ): IActionType<UserAccountExportGenerationRequestTypes> => ({
        payload,
        type: USER_ACCOUNT_EXPORT_GENERATE.REQUEST,
    }),
    success: (payload: UserAccountExportTypes): IActionType<UserAccountExportTypes> => ({
        payload,
        type: USER_ACCOUNT_EXPORT_GENERATE.SUCCESS,
    }),
};

export const downloadUserAccountExport = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: USER_ACCOUNT_EXPORT_DOWNLOAD.ERROR,
    }),
    request: (payload: UserAccountExportDownloadRequestTypes): IActionType<UserAccountExportDownloadRequestTypes> => ({
        payload,
        type: USER_ACCOUNT_EXPORT_DOWNLOAD.REQUEST,
    }),
    success: () => ({
        type: USER_ACCOUNT_EXPORT_DOWNLOAD.SUCCESS,
    }),
};
