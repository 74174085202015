import {CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';

export const getCustomAttributesFilteredList = (
    customAttributes: CustomAttributesItemTypes[],
    isClientMode: boolean,
    isUserEditEnabled: boolean,
): CustomAttributesItemTypes[] => {
    return customAttributes.filter(attribute => {
        const {isManaged, isOnlyOrganizationUser} = attribute;
        if (isClientMode && (isOnlyOrganizationUser || isManaged)) {
            return false;
        } else if (!isUserEditEnabled && isManaged) {
            return false;
        }
        return true;
    });
};
