import React, {ElementType, FC} from 'react';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import {ERROR_TEXT_COLOR, MAIN_CLIENT_COLOR} from 'config/theme';

interface HasNotApprovedStatusChangeRequestsButtonType {
    title: string;
    icon: ElementType;
    onClickAction?: () => void;
    isGreenButton?: boolean;
    isRequesterPage?: boolean;
}

const HasNotApprovedStatusChangeRequestsButton: FC<HasNotApprovedStatusChangeRequestsButtonType> = ({
    title,
    icon,
    onClickAction,
    isGreenButton,
    isRequesterPage,
}) => {
    const Icon = icon;
    return (
        <Tooltip title={title}>
            <IconButton
                onClick={onClickAction}
                sx={{
                    ml: isRequesterPage ? 0 : 1,
                    mr: isRequesterPage ? 1 : 0,
                    backgroundColor: isGreenButton ? MAIN_CLIENT_COLOR : ERROR_TEXT_COLOR,
                    '&:hover': {
                        backgroundColor: isGreenButton ? MAIN_CLIENT_COLOR : ERROR_TEXT_COLOR,
                    },
                }}
            >
                <Icon isActive />
            </IconButton>
        </Tooltip>
    );
};

export default HasNotApprovedStatusChangeRequestsButton;
