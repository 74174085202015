import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import ClientButton from 'components/ClientScreenComponents/ClientButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CancelRequesterCaseForm from 'components/Forms/RequesterCase/CancelRequesterCaseForm';

interface RequesterCaseCancelButtonType {
    caseId: number;
}

const RequesterCaseCancelButton: FC<RequesterCaseCancelButtonType> = ({caseId}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <ClientButton
                title={t('requester.casesList.cancelCaseButton')}
                onClickAction={() => setIsOpened(true)}
                isError
                endIcon={<CloseSvgIcon isActive />}
            />
            <ModalWrapper
                title={t('requester.casesList.cancelCaseButton')}
                isShowModal={isOpened}
                toggleModal={toggleModal}
            >
                <Typography>{'requester.casesList.cancelReasonExplanation'}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 2,
                        '& form': {
                            width: '100%',
                        },
                    }}
                >
                    <CancelRequesterCaseForm caseId={caseId} closeModal={() => setIsOpened(false)} />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default RequesterCaseCancelButton;
