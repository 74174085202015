import {call, fork, put, take} from 'redux-saga/effects';

import {
    GET_CUSTOM_ATTRIBUTES_LIST,
    getCustomAttributesList,
    GET_GLOBAL_ATTRIBUTES_LIST,
    getGlobalAttributesList,
    CREATE_CUSTOM_ATTRIBUTE,
    createCustomAttribute,
    ATTACH_CUSTOM_ATTRIBUTE,
    attachCustomAttribute,
    UPDATE_CUSTOM_ATTRIBUTE,
    updateCustomAttribute,
    DELETE_CUSTOM_ATTRIBUTE,
    deleteCustomAttribute,
    TRANSLATE_CUSTOM_ATTRIBUTE,
    translateCustomAttribute,
    CREATE_CUSTOM_ATTRIBUTE_OPTION,
    createCustomAttributeOption,
    UPDATE_CUSTOM_ATTRIBUTE_OPTION,
    updateCustomAttributeOption,
    DELETE_CUSTOM_ATTRIBUTE_OPTION,
    deleteCustomAttributeOption,
} from 'appRedux/actions/customAttributes';

import {
    CustomAttributesListResponseTypes,
    CustomAttributesListRequestTypes,
    CustomAttributeCreateRequestTypes,
    CustomAttributeUpdateRequestTypes,
    CustomAttributeDeletingRequestTypes,
    CustomAttributesAttachRequestTypes,
    CustomAttributeTranslationRequestTypes,
    CustomAttributeOptionCreateRequestTypes,
    CustomAttributeOptionUpdateRequestTypes,
    CustomAttributeOptionDeleteRequestTypes,
} from 'appRedux/actions/customAttributes/types';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchCustomAttributesListRequest() {
    while (true) {
        const {
            payload: {showAlert, callback},
        }: IActionType<CustomAttributesListRequestTypes> = yield take(GET_CUSTOM_ATTRIBUTES_LIST.REQUEST);
        try {
            const data: CustomAttributesListResponseTypes = yield call(http, `attributes/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getCustomAttributesList.success(data.results));
                callback && callback();
            } else {
                yield put(getCustomAttributesList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getCustomAttributesList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGlobalAttributesListRequest() {
    while (true) {
        const {
            payload: {showAlert, callback},
        }: IActionType<CustomAttributesListRequestTypes> = yield take(GET_GLOBAL_ATTRIBUTES_LIST.REQUEST);
        try {
            const data: CustomAttributesListResponseTypes = yield call(http, `attributes/global/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getGlobalAttributesList.success(data.results));
                callback && callback();
            } else {
                yield put(getGlobalAttributesList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getGlobalAttributesList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCustomAttributeCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, ...values},
        }: IActionType<CustomAttributeCreateRequestTypes> = yield take(CREATE_CUSTOM_ATTRIBUTE.REQUEST);
        try {
            const data: CustomAttributesListResponseTypes = yield call(http, `attribute/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createCustomAttribute.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(createCustomAttribute.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createCustomAttribute.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCustomAttributeAttaching() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<CustomAttributesAttachRequestTypes> = yield take(ATTACH_CUSTOM_ATTRIBUTE.REQUEST);
        try {
            const data: CustomAttributesListResponseTypes = yield call(http, `attribute/${uuid}/attach`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(attachCustomAttribute.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(attachCustomAttribute.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(attachCustomAttribute.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCustomAttributeUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<CustomAttributeUpdateRequestTypes> = yield take(UPDATE_CUSTOM_ATTRIBUTE.REQUEST);
        try {
            const data: CustomAttributesListResponseTypes = yield call(http, `attribute/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateCustomAttribute.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(updateCustomAttribute.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateCustomAttribute.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCustomAttributeDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<CustomAttributeDeletingRequestTypes> = yield take(DELETE_CUSTOM_ATTRIBUTE.REQUEST);
        try {
            const data: CustomAttributesListResponseTypes = yield call(http, `attribute/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteCustomAttribute.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(deleteCustomAttribute.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteCustomAttribute.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCustomAttributeTranslation() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<CustomAttributeTranslationRequestTypes> = yield take(TRANSLATE_CUSTOM_ATTRIBUTE.REQUEST);
        try {
            const data: CustomAttributesListResponseTypes = yield call(http, `attribute/${uuid}/translate`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(translateCustomAttribute.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(translateCustomAttribute.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(translateCustomAttribute.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCustomAttributeOptionCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<CustomAttributeOptionCreateRequestTypes> = yield take(CREATE_CUSTOM_ATTRIBUTE_OPTION.REQUEST);
        try {
            const data: CustomAttributesListResponseTypes = yield call(http, `attribute/${uuid}/option/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createCustomAttributeOption.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(createCustomAttributeOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createCustomAttributeOption.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCustomAttributeOptionUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<CustomAttributeOptionUpdateRequestTypes> = yield take(UPDATE_CUSTOM_ATTRIBUTE_OPTION.REQUEST);
        try {
            const data: CustomAttributesListResponseTypes = yield call(http, `attribute/${uuid}/option/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateCustomAttributeOption.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(updateCustomAttributeOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateCustomAttributeOption.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCustomAttributeOptionDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<CustomAttributeOptionDeleteRequestTypes> = yield take(DELETE_CUSTOM_ATTRIBUTE_OPTION.REQUEST);
        try {
            const data: CustomAttributesListResponseTypes = yield call(http, `attribute/${uuid}/option/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteCustomAttributeOption.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(deleteCustomAttributeOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteCustomAttributeOption.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchCustomAttributesListRequest),
    fork(watchGlobalAttributesListRequest),
    fork(watchCustomAttributeCreation),
    fork(watchCustomAttributeAttaching),
    fork(watchCustomAttributeUpdating),
    fork(watchCustomAttributeDeleting),
    fork(watchCustomAttributeTranslation),
    fork(watchCustomAttributeOptionCreation),
    fork(watchCustomAttributeOptionUpdating),
    fork(watchCustomAttributeOptionDeleting),
];
