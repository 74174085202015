import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import {FORM_CHANGE_STATUS, FORM_PAUSE_OFF} from 'appRedux/actions/forms';
import {FormItemBasic} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {isMaximalActiveFormsNumberReached} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';
import ActiveFormsNumberQuotaAlertPopup from 'pages/admin/updateForm/partials/ActiveFormsNumberQuotaAlertPopup';
import AgentMultiWayToggle from 'components/AgentScreenComponents/_buttons/AgentMultiWayToggle';
import {ERROR_TEXT_COLOR, HIGHLIGHTED_SECTION_COLOR, MAIN_CLIENT_COLOR} from 'config/theme';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import PauseForm from 'components/Forms/FormBuilder/PauseForm/PauseForm';

enum FormStatusOption {
    stop = 'stop',
    pause = 'pause',
    active = 'active',
}

interface FormStatusSwitcherType {
    currentForm: FormItemBasic;
}

const FormStatusSwitcher: FC<FormStatusSwitcherType> = ({currentForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {id, isPaused, isSubForm, isActive} = currentForm;

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const changeFormStatus = useCallback(
        data => dispatch({type: FORM_CHANGE_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const formPauseOff = useCallback(data => dispatch({type: FORM_PAUSE_OFF.REQUEST, payload: data}), [dispatch]);

    const {
        admin: {organizationList, formsList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const activeForms = formsList ? formsList.filter(item => item.isActive) : [];

    const [isShowPauseReasonModal, setIsShowPauseReasonModal] = useState<boolean>(false);
    const togglePauseReasonModal = () => {
        setIsShowPauseReasonModal(previous => !previous);
    };

    const [status, setStatus] = useState<FormStatusOption>();

    const toggleIsCheckedStatus = () => {
        setStatus(previous =>
            previous === FormStatusOption.active
                ? FormStatusOption.stop
                : previous === FormStatusOption.stop
                ? FormStatusOption.active
                : previous,
        );
    };

    useEffect(() => {
        if (isPaused) {
            setStatus(FormStatusOption.pause);
        } else if (isActive) {
            setStatus(FormStatusOption.active);
        } else {
            setStatus(FormStatusOption.stop);
        }
    }, [isActive, isPaused]);

    const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: FormStatusOption) => {
        if (myOrganization) {
            if (newValue === FormStatusOption.pause) {
                togglePauseReasonModal();
                // setStatus(newValue);
            } else if (
                newValue === FormStatusOption.active &&
                isMaximalActiveFormsNumberReached(myOrganization, activeForms.length + 1, false)
            ) {
                setIsOpened(true);
            } else {
                if (isPaused) {
                    formPauseOff({
                        id,
                        showAlert,
                    });
                } else {
                    changeFormStatus({
                        id,
                        showAlert,
                        callbackError: toggleIsCheckedStatus,
                    });
                }
                setStatus(newValue);
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 0.5,
            }}
        >
            {status && (
                <AgentMultiWayToggle<FormStatusOption>
                    value={status}
                    options={[
                        {
                            value: FormStatusOption.stop,
                            Icon: StopIcon,
                            backgroundColor: ERROR_TEXT_COLOR,
                        },
                        {
                            value: FormStatusOption.pause,
                            Icon: PauseIcon,
                            backgroundColor: HIGHLIGHTED_SECTION_COLOR,
                            disabled: isSubForm,
                        },
                        {
                            value: FormStatusOption.active,
                            Icon: PlayArrowIcon,
                            backgroundColor: MAIN_CLIENT_COLOR,
                        },
                    ]}
                    handleChange={handleChange}
                />
            )}
            <ActiveFormsNumberQuotaAlertPopup
                formId={Number(id)}
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                toggleIsOpened={toggleIsOpened}
                toggleIsChecked={toggleIsCheckedStatus}
                changeFormStatus={changeFormStatus}
            />
            <ModalWrapper
                title={t('orguser.forms.pauseOn')}
                isShowModal={isShowPauseReasonModal}
                toggleModal={togglePauseReasonModal}
                isWideModal
            >
                <PauseForm form={currentForm} toggleModal={togglePauseReasonModal} />
            </ModalWrapper>
        </Box>
    );
};

export default FormStatusSwitcher;
