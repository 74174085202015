import {all, SimpleEffect, AllEffect, ForkEffectDescriptor} from 'redux-saga/effects';

import admin from 'appRedux/sagas/admin';
import agentFilter from 'appRedux/sagas/agentFilter';
import analytics from 'appRedux/sagas/analytics';
import auth from 'appRedux/sagas/auth';
import clientTranslation from 'appRedux/sagas/clientTranslation';
import crypto from 'appRedux/sagas/crypto';
import customAttributes from 'appRedux/sagas/customAttributes';
import groups from 'appRedux/sagas/groups';
import profile from 'appRedux/sagas/profile';
import organization from 'appRedux/sagas/organization';
import organizationAdmin from 'appRedux/sagas/organizationAdmin';
import organizationLanguage from 'appRedux/sagas/organizationLanguage';
import organizationPrivacyPolicy from 'appRedux/sagas/organizationPrivacyPolicy';
import organizationIntegrationTokens from 'appRedux/sagas/organizationIntegrationTokens';
import organizationPdfTemplate from 'appRedux/sagas/organizationPdfTemplate';
import instance from 'appRedux/sagas/instance';
import invites from 'appRedux/sagas/invites';
import forms from 'appRedux/sagas/forms';
import formLanguage from 'appRedux/sagas/formLanguage';
import formPages from 'appRedux/sagas/formPages';
import formSections from 'appRedux/sagas/formSections';
import formSnippet from 'appRedux/sagas/formSnippet';
import formFaq from 'appRedux/sagas/formFaq';
import formFields from 'appRedux/sagas/formFields';
import formPopup from 'appRedux/sagas/formPopup';
import formTags from 'appRedux/sagas/formTags';
import formHasAdditional from 'appRedux/sagas/formHasAdditional';
import formVersions from 'appRedux/sagas/formVersions';
import formCards from 'appRedux/sagas/formCards';
import formWhiteListEmails from 'appRedux/sagas/formWhiteList';
import generalTemplates from 'appRedux/sagas/generalTemplates';
import notifications from 'appRedux/sagas/notifications';
import requesterCase from 'appRedux/sagas/requesterCase';
import requestChat from 'appRedux/sagas/requestChat';
import resourceFields from 'appRedux/sagas/resourceFields';
import roles from 'appRedux/sagas/roles';
import rules from 'appRedux/sagas/rules';
import superadmin from 'appRedux/sagas/superadmin';
import comments from 'appRedux/sagas/comments';
import workflow from 'appRedux/sagas/workflow';

export default function* rootSaga(): Generator<AllEffect<SimpleEffect<'FORK', ForkEffectDescriptor<void>>>> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sagas: SimpleEffect<'FORK', ForkEffectDescriptor<void>>[] = [
        ...admin,
        ...auth,
        ...agentFilter,
        ...analytics,
        ...clientTranslation,
        ...crypto,
        ...customAttributes,
        ...groups,
        ...profile,
        ...organization,
        ...organizationAdmin,
        ...organizationLanguage,
        ...organizationPrivacyPolicy,
        ...organizationIntegrationTokens,
        ...organizationPdfTemplate,
        ...instance,
        ...invites,
        ...forms,
        ...formLanguage,
        ...formPages,
        ...formSections,
        ...formSnippet,
        ...formFaq,
        ...formFields,
        ...formPopup,
        ...formTags,
        ...formHasAdditional,
        ...formVersions,
        ...formCards,
        ...formWhiteListEmails,
        ...generalTemplates,
        ...notifications,
        ...requesterCase,
        ...requestChat,
        ...resourceFields,
        ...roles,
        ...rules,
        ...superadmin,
        ...comments,
        ...workflow,
    ];

    yield all(sagas);
}
