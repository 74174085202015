import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {GET_GLOBAL_ATTRIBUTES_LIST, ATTACH_CUSTOM_ATTRIBUTE} from 'appRedux/actions/customAttributes';
import {CustomAttributesAttachFormTypes, CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationAttachmentSchema} from 'components/Forms/CustomAttributes/validation';

interface AttachCustomAttributeToOrganizationFormType {
    closeModal: () => void;
}

const AttachCustomAttributeToOrganizationForm: FC<AttachCustomAttributeToOrganizationFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        instance: {globalAttributes, customAttributes},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getGlobalAttributesList = useCallback(
        data => dispatch({type: GET_GLOBAL_ATTRIBUTES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const attachCustomAttribute = useCallback(
        data => dispatch({type: ATTACH_CUSTOM_ATTRIBUTE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: CustomAttributesAttachFormTypes) => {
        attachCustomAttribute({
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    useEffect(() => {
        getGlobalAttributesList({
            showAlert,
        });
    }, []);

    return (
        <Box>
            <Formik
                enableReinitialize
                initialValues={{
                    uuid: '',
                }}
                validationSchema={validationAttachmentSchema}
                onSubmit={onSubmitClicked}
            >
                {(formik: FormikProps<CustomAttributesAttachFormTypes>) => {
                    return (
                        <Form>
                            <FormControl sx={{mt: 1}} required fullWidth>
                                <InputLabel>{t('superadmin.customAttributes.selectGlobalAttribute')}</InputLabel>
                                <Select
                                    label={t('superadmin.customAttributes.selectGlobalAttribute')}
                                    name={'uuid'}
                                    value={formik.values.uuid}
                                    onChange={formik.handleChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    fullWidth
                                >
                                    {globalAttributes &&
                                        globalAttributes.map((item: CustomAttributesItemTypes) => {
                                            const {uuid, title} = item;
                                            const isAlreadyAttached = customAttributes.some(
                                                attribute => Number(attribute.parentId) === item.id,
                                            );
                                            if (!isAlreadyAttached) {
                                                return (
                                                    <MenuItem key={`attribute-${uuid}`} value={uuid}>
                                                        <Typography sx={{fontWeight: 600}}>{title}</Typography>
                                                    </MenuItem>
                                                );
                                            }
                                            return null;
                                        })}
                                </Select>
                            </FormControl>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: 3,
                                }}
                            >
                                <AgentSaveButton isSubmit />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default AttachCustomAttributeToOrganizationForm;
