import {
    INSTANCE_SETTINGS_LIST,
    INSTANCE_SETTING_UPDATE,
    INSTANCE_SETTING_UPLOAD,
    INSTANCE_SETTING_UPLOAD_FAVICON,
    INSTANCE_SETTING_FILES,
    INSTANCE_SETTING_DELETE_FILE,
    INSTANCE_SETTING_DELETE_FAVICON,
} from 'appRedux/actions/instance';

import {
    GET_CUSTOM_ATTRIBUTES_LIST,
    GET_GLOBAL_ATTRIBUTES_LIST,
    CREATE_CUSTOM_ATTRIBUTE,
    ATTACH_CUSTOM_ATTRIBUTE,
    UPDATE_CUSTOM_ATTRIBUTE,
    DELETE_CUSTOM_ATTRIBUTE,
    TRANSLATE_CUSTOM_ATTRIBUTE,
    CREATE_CUSTOM_ATTRIBUTE_OPTION,
    UPDATE_CUSTOM_ATTRIBUTE_OPTION,
    DELETE_CUSTOM_ATTRIBUTE_OPTION,
} from 'appRedux/actions/customAttributes';

import {InstanceSettingBasic, InstanceSettingsFile} from 'appRedux/actions/instance/types';
import {CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';

export const initialState: InstanceSettingsState = {
    isLoading: false,
    customAttributes: [],
    globalAttributes: [],
    settings: [],
    files: [],
    errors: undefined,
};

export interface InstanceSettingsState {
    isLoading: boolean;
    customAttributes: CustomAttributesItemTypes[];
    globalAttributes: CustomAttributesItemTypes[];
    settings: InstanceSettingBasic[];
    files: InstanceSettingsFile[];
    errors: string[] | undefined;
}

const instance = (
    state = initialState,
    action: IActionType<InstanceSettingBasic[] | InstanceSettingsFile[] | CustomAttributesItemTypes[]>,
): InstanceSettingsState => {
    switch (action.type) {
        case INSTANCE_SETTINGS_LIST.REQUEST:
        case INSTANCE_SETTING_UPDATE.REQUEST:
        case INSTANCE_SETTING_UPLOAD.REQUEST:
        case INSTANCE_SETTING_UPLOAD_FAVICON.REQUEST:
        case INSTANCE_SETTING_DELETE_FAVICON.REQUEST:
        case INSTANCE_SETTING_FILES.REQUEST:
        case INSTANCE_SETTING_DELETE_FILE.REQUEST:
        case GET_CUSTOM_ATTRIBUTES_LIST.REQUEST:
        case GET_GLOBAL_ATTRIBUTES_LIST.REQUEST:
        case CREATE_CUSTOM_ATTRIBUTE.REQUEST:
        case ATTACH_CUSTOM_ATTRIBUTE.REQUEST:
        case UPDATE_CUSTOM_ATTRIBUTE.REQUEST:
        case DELETE_CUSTOM_ATTRIBUTE.REQUEST:
        case TRANSLATE_CUSTOM_ATTRIBUTE.REQUEST:
        case CREATE_CUSTOM_ATTRIBUTE_OPTION.REQUEST:
        case UPDATE_CUSTOM_ATTRIBUTE_OPTION.REQUEST:
        case DELETE_CUSTOM_ATTRIBUTE_OPTION.REQUEST: {
            return {
                ...state,
                isLoading: true,
                errors: undefined,
            };
        }

        case INSTANCE_SETTINGS_LIST.SUCCESS:
        case INSTANCE_SETTING_UPDATE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                settings: action.payload as InstanceSettingBasic[],
                errors: undefined,
            };
        }

        case INSTANCE_SETTING_UPLOAD.SUCCESS:
        case INSTANCE_SETTING_UPLOAD_FAVICON.SUCCESS:
        case INSTANCE_SETTING_DELETE_FAVICON.SUCCESS:
        case INSTANCE_SETTING_FILES.SUCCESS:
        case INSTANCE_SETTING_DELETE_FILE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                files: action.payload as InstanceSettingsFile[],
            };
        }

        case GET_CUSTOM_ATTRIBUTES_LIST.SUCCESS:
        case CREATE_CUSTOM_ATTRIBUTE.SUCCESS:
        case ATTACH_CUSTOM_ATTRIBUTE.SUCCESS:
        case UPDATE_CUSTOM_ATTRIBUTE.SUCCESS:
        case DELETE_CUSTOM_ATTRIBUTE.SUCCESS:
        case TRANSLATE_CUSTOM_ATTRIBUTE.SUCCESS:
        case CREATE_CUSTOM_ATTRIBUTE_OPTION.SUCCESS:
        case UPDATE_CUSTOM_ATTRIBUTE_OPTION.SUCCESS:
        case DELETE_CUSTOM_ATTRIBUTE_OPTION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customAttributes: action.payload as CustomAttributesItemTypes[],
            };
        }

        case GET_GLOBAL_ATTRIBUTES_LIST.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                globalAttributes: action.payload as CustomAttributesItemTypes[],
            };
        }

        case INSTANCE_SETTINGS_LIST.ERROR:
        case INSTANCE_SETTING_UPDATE.ERROR:
        case INSTANCE_SETTING_UPLOAD.ERROR:
        case INSTANCE_SETTING_UPLOAD_FAVICON.ERROR:
        case INSTANCE_SETTING_DELETE_FAVICON.ERROR:
        case INSTANCE_SETTING_FILES.ERROR:
        case INSTANCE_SETTING_DELETE_FILE.ERROR:
        case GET_CUSTOM_ATTRIBUTES_LIST.ERROR:
        case GET_GLOBAL_ATTRIBUTES_LIST.ERROR:
        case CREATE_CUSTOM_ATTRIBUTE.ERROR:
        case ATTACH_CUSTOM_ATTRIBUTE.ERROR:
        case UPDATE_CUSTOM_ATTRIBUTE.ERROR:
        case DELETE_CUSTOM_ATTRIBUTE.ERROR:
        case TRANSLATE_CUSTOM_ATTRIBUTE.ERROR:
        case CREATE_CUSTOM_ATTRIBUTE_OPTION.ERROR:
        case UPDATE_CUSTOM_ATTRIBUTE_OPTION.ERROR:
        case DELETE_CUSTOM_ATTRIBUTE_OPTION.ERROR: {
            return {
                ...state,
                isLoading: false,
                errors: action.payload as any,
            };
        }

        default:
            return state;
    }
};

export default instance;
