import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const UserAttributeSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
            <rect width="16" height="16" transform="translate(0 0.513184)" fill="white" fillOpacity="0.01" />
            <path
                d="M6 2.51318C6.49445 2.51318 6.9778 2.65981 7.38893 2.93451C7.80005 3.20921 8.12048 3.59966 8.3097 4.05647C8.49892 4.51329 8.54843 5.01596 8.45196 5.50091C8.3555 5.98586 8.1174 6.43132 7.76777 6.78095C7.41814 7.13058 6.97268 7.36868 6.48773 7.46515C6.00277 7.56161 5.50011 7.5121 5.04329 7.32288C4.58648 7.13366 4.19603 6.81323 3.92133 6.40211C3.64662 5.99099 3.5 5.50764 3.5 5.01318C3.5 4.35014 3.76339 3.71426 4.23223 3.24542C4.70107 2.77658 5.33696 2.51318 6 2.51318ZM6 1.51318C5.30777 1.51318 4.63108 1.71846 4.0555 2.10304C3.47993 2.48762 3.03133 3.03425 2.76642 3.67379C2.50151 4.31333 2.4322 5.01707 2.56725 5.696C2.7023 6.37493 3.03564 6.99857 3.52513 7.48806C4.01461 7.97754 4.63825 8.31088 5.31718 8.44593C5.99612 8.58098 6.69985 8.51167 7.33939 8.24676C7.97893 7.98185 8.52556 7.53325 8.91014 6.95768C9.29473 6.38211 9.5 5.70542 9.5 5.01318C9.5 4.08493 9.13125 3.19469 8.47487 2.53831C7.8185 1.88193 6.92826 1.51318 6 1.51318Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M11 15.5132H10V13.0132C10 12.3501 9.73661 11.7143 9.26777 11.2454C8.79893 10.7766 8.16304 10.5132 7.5 10.5132H4.5C3.83696 10.5132 3.20107 10.7766 2.73223 11.2454C2.26339 11.7143 2 12.3501 2 13.0132V15.5132H1V13.0132C1 12.0849 1.36875 11.1947 2.02513 10.5383C2.6815 9.88193 3.57174 9.51318 4.5 9.51318H7.5C8.42826 9.51318 9.3185 9.88193 9.97487 10.5383C10.6313 11.1947 11 12.0849 11 13.0132V15.5132Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path d="M16 2.51318H11V3.51318H16V2.51318Z" fill={getIconColor(isActive, isDisabled)} />
            <path d="M16 5.01318H11V6.01318H16V5.01318Z" fill={getIconColor(isActive, isDisabled)} />
            <path d="M14.5 7.51318H11V8.51318H14.5V7.51318Z" fill={getIconColor(isActive, isDisabled)} />
        </svg>
    );
};

export default UserAttributeSvgIcon;
