import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_CUSTOM_ATTRIBUTE_OPTION} from 'appRedux/actions/customAttributes';
import {CustomAttributeOptionFormTypes, CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';

import {AlertContext} from 'contexts/alert/context';

import CustomAttributeOptionForm from 'components/Forms/CustomAttributes/CustomAttributeOptionForm';

interface CreateCustomAttributeFormType {
    item: CustomAttributesItemTypes;
    closeModal: () => void;
}

const CreateCustomAttributeForm: FC<CreateCustomAttributeFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {uuid, options} = item;

    const {showAlert} = useContext(AlertContext);

    const createCustomAttribute = useCallback(
        data => dispatch({type: CREATE_CUSTOM_ATTRIBUTE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: CustomAttributeOptionFormTypes) => {
        createCustomAttribute({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <CustomAttributeOptionForm
            initialValues={{
                title: '',
            }}
            options={options}
            onSubmitClicked={onSubmitClicked}
            isCreate
        />
    );
};

export default CreateCustomAttributeForm;
