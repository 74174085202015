import {requestTypeCreator} from 'helpers/index';

import {
    OrganizationAdminListTypes,
    OrganizationAdminCreateTypes,
    OrganizationAdminInviteTypes,
    OrganizationAdminRequestTypes,
    OrganizationAdminRolesRequestTypes,
    OrganizationAdminResetPasswordTypes,
    OrganizationAdminUnlockTypes,
    OrganizationAdminDeleteTypes,
    OrganizationAdminReassignTypes,
    OrganizationAdminRelateTypes,
    RelateOrganizationAdminOtherOrganizationRequestTypes,
    OrganizationAdminUpdateCustomAttributeRequestTypes,
} from 'appRedux/actions/organizationAdmin/types';

export const ORGANIZATION_ADMIN_LIST = requestTypeCreator('ORGANIZATION_ADMIN_LIST');
export const ORGANIZATION_ADMIN_CREATE = requestTypeCreator('ORGANIZATION_ADMIN_CREATE');
export const ORGANIZATION_ADMIN_INVITE = requestTypeCreator('ORGANIZATION_ADMIN_INVITE');
export const ORGANIZATION_ADMIN_UPDATE = requestTypeCreator('ORGANIZATION_ADMIN_UPDATE');
export const ORGANIZATION_ADMIN_PASSWORD_RESET = requestTypeCreator('ORGANIZATION_ADMIN_PASSWORD_RESET');
export const ORGANIZATION_ADMIN_UNLOCK = requestTypeCreator('ORGANIZATION_ADMIN_UNLOCK');
export const ORGANIZATION_ADMIN_DELETE = requestTypeCreator('ORGANIZATION_ADMIN_DELETE');
export const ORGANIZATION_ADMIN_2FA_DEACTIVATE = requestTypeCreator('ORGANIZATION_ADMIN_2FA_DEACTIVATE');
export const ORGANIZATION_AGENT_REASSIGN = requestTypeCreator('ORGANIZATION_AGENT_REASSIGN');
export const ORGANIZATION_ADMIN_UPDATE_ROLES = requestTypeCreator('ORGANIZATION_ADMIN_UPDATE_ROLES');
export const ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION = requestTypeCreator(
    'ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION',
);
export const ORGANIZATION_ADMIN_UPDATE_CUSTOM_ATTRIBUTE = requestTypeCreator(
    'ORGANIZATION_ADMIN_UPDATE_CUSTOM_ATTRIBUTE',
);

export const getOrganizationAdminsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_LIST.ERROR,
    }),
    request: (payload: OrganizationAdminRequestTypes): IActionType<OrganizationAdminRequestTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_LIST.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_LIST.SUCCESS,
    }),
};

export const createOrganizationAdmin = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_CREATE.ERROR,
    }),
    request: (payload: OrganizationAdminCreateTypes): IActionType<OrganizationAdminCreateTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_CREATE.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_CREATE.SUCCESS,
    }),
};

export const inviteOrganizationAdmin = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_INVITE.ERROR,
    }),
    request: (payload: OrganizationAdminInviteTypes): IActionType<OrganizationAdminInviteTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_INVITE.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_INVITE.SUCCESS,
    }),
};

export const updateOrganizationAdmin = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_UPDATE.ERROR,
    }),
    request: (payload: OrganizationAdminCreateTypes): IActionType<OrganizationAdminCreateTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_UPDATE.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_UPDATE.SUCCESS,
    }),
};

export const resetPasswordOrganizationAdmin = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_PASSWORD_RESET.ERROR,
    }),
    request: (payload: OrganizationAdminResetPasswordTypes): IActionType<OrganizationAdminResetPasswordTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_PASSWORD_RESET.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_PASSWORD_RESET.SUCCESS,
    }),
};

export const unlockOrganizationAdmin = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_UNLOCK.ERROR,
    }),
    request: (payload: OrganizationAdminUnlockTypes): IActionType<OrganizationAdminUnlockTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_UNLOCK.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_UNLOCK.SUCCESS,
    }),
};

export const deleteOrganizationAdmin = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_DELETE.ERROR,
    }),
    request: (payload: OrganizationAdminDeleteTypes): IActionType<OrganizationAdminDeleteTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_DELETE.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_DELETE.SUCCESS,
    }),
};

export const deactivateOrgAdminTwoFactorAuthorization = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_2FA_DEACTIVATE.ERROR,
    }),
    request: (payload: OrganizationAdminRelateTypes): IActionType<OrganizationAdminRelateTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_2FA_DEACTIVATE.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_2FA_DEACTIVATE.SUCCESS,
    }),
};

export const reassignOrganizationAgent = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_AGENT_REASSIGN.ERROR,
    }),
    request: (payload: OrganizationAdminReassignTypes): IActionType<OrganizationAdminReassignTypes> => ({
        payload,
        type: ORGANIZATION_AGENT_REASSIGN.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_AGENT_REASSIGN.SUCCESS,
    }),
};

export const updateOrganizationAdminRoles = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_UPDATE_ROLES.ERROR,
    }),
    request: (payload: OrganizationAdminRolesRequestTypes): IActionType<OrganizationAdminRolesRequestTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_UPDATE_ROLES.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_UPDATE_ROLES.SUCCESS,
    }),
};

export const relateOrganizationAdminToOtherOrganization = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION.ERROR,
    }),
    request: (
        payload: RelateOrganizationAdminOtherOrganizationRequestTypes,
    ): IActionType<RelateOrganizationAdminOtherOrganizationRequestTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION.SUCCESS,
    }),
};

export const updateOrganizationAdminCustomAttribute = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_ADMIN_UPDATE_CUSTOM_ATTRIBUTE.ERROR,
    }),
    request: (
        payload: OrganizationAdminUpdateCustomAttributeRequestTypes,
    ): IActionType<OrganizationAdminUpdateCustomAttributeRequestTypes> => ({
        payload,
        type: ORGANIZATION_ADMIN_UPDATE_CUSTOM_ATTRIBUTE.REQUEST,
    }),
    success: (payload: OrganizationAdminListTypes[]): IActionType<OrganizationAdminListTypes[]> => ({
        payload,
        type: ORGANIZATION_ADMIN_UPDATE_CUSTOM_ATTRIBUTE.SUCCESS,
    }),
};
