import {CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';

export const CUSTOM_ATTRIBUTE_TYPE_TEXT_INPUT = 0;
export const CUSTOM_ATTRIBUTE_TYPE_TEXTAREA = 1;
export const CUSTOM_ATTRIBUTE_TYPE_DROPDOWN = 2;

export interface CustomAttributeTypes {
    value: number;
    label: string;
}

export const customAttributeTypes: CustomAttributeTypes[] = [
    {
        value: CUSTOM_ATTRIBUTE_TYPE_TEXT_INPUT,
        label: 'superadmin.customAttributes.types.textInput',
    },
    {
        value: CUSTOM_ATTRIBUTE_TYPE_TEXTAREA,
        label: 'superadmin.customAttributes.types.textArea',
    },
    {
        value: CUSTOM_ATTRIBUTE_TYPE_DROPDOWN,
        label: 'superadmin.customAttributes.types.dropdown',
    },
];

export const getCustomAttributeTypeLabel = (type: number): string => {
    const currentType = customAttributeTypes.find(item => item.value === type);
    return currentType ? currentType.label : 'common.none';
};

export const getCustomAttributeTranslationKeyword = (attributeId: number, element: string): string => {
    return `attribute-${attributeId}-${element}`;
};

export const getCustomAttributeOptionTranslationKeyword = (attributeId: number, optionId: number): string => {
    return `attribute-${attributeId}-option-${optionId}`;
};

export const getCustomAttributeTranslatedLabel = (
    customAttribute: CustomAttributesItemTypes,
    keyword: string,
    languageId: number,
    defaultValue: string,
): string => {
    const {translations, id, parentId} = customAttribute;
    const translation = translations.find(
        translation => translation.language === languageId && translation.keyword === keyword,
    );
    return translation ? translation.translation : defaultValue;
};
