import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {UserHasCustomAttributeTypes} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';

import OrganizationAdminCustomAttributeForm from 'components/Forms/OrganizationAdminForm/OrganizationAdminCustomAttributeForm';

interface OrganizationAdminCustomAttributesType {
    organizationAdminId: number;
    userAttributes?: UserHasCustomAttributeTypes[];
}

const OrganizationAdminCustomAttributes: FC<OrganizationAdminCustomAttributesType> = ({
    organizationAdminId,
    userAttributes,
}) => {
    const {
        instance: {customAttributes},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const filteredCustomAttributes = customAttributes.filter(item => item.isManaged && item.isOnlyOrganizationUser);

    return (
        <Box sx={{mb: 1}}>
            {filteredCustomAttributes.map(item => {
                const userAttribute = userAttributes
                    ? userAttributes.find(userAttribute => userAttribute.attribute === item.id)
                    : null;
                return (
                    <OrganizationAdminCustomAttributeForm
                        customAttribute={item}
                        organizationAdminId={organizationAdminId}
                        userAttribute={userAttribute}
                        key={`orguser-custom-attribute-${item.uuid}`}
                    />
                );
            })}
        </Box>
    );
};

export default OrganizationAdminCustomAttributes;
