import React, {FC, MouseEvent, TouchEvent, useCallback, useContext, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useDispatch} from 'react-redux';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import {SxProps} from '@mui/material';
import Box from '@mui/system/Box';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {MessageTypes} from 'appRedux/actions/requestChat/types';
import {DELETE_CHAT_MESSAGE} from 'appRedux/actions/requestChat';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateChatMessageForm from 'components/Forms/RequesterCaseChatForm/UpdateChatMessageForm';

import {theme} from 'config/theme';

interface ChatMessageBlockOptionsType {
    item: MessageTypes;
    userId: number;
    isButtonShow: boolean;
    isCurrentUserSender: boolean;
    children: React.ReactNode;
    wrapperSx: SxProps;
}

const ChatMessageBlockOptions: FC<ChatMessageBlockOptionsType> = ({
    item,
    isButtonShow,
    userId,
    isCurrentUserSender,
    children,
    wrapperSx,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isMobile} = useContext(MediaContext);

    const {createdAt, uuid, isSystemMessage} = item;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isUpdateOpened, setIsUpdateOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const toggleIsUpdateOpened = () => {
        setIsUpdateOpened(previous => !previous);
    };

    const removeChatMessage = useCallback(
        data => dispatch({type: DELETE_CHAT_MESSAGE.REQUEST, payload: data}),
        [dispatch],
    );

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        console.log('handleClose');
        setAnchorEl(null);
    };

    const isMessageChangingPossible = moment(createdAt).add(1, 'hour').unix() > moment().unix();

    const open = Boolean(anchorEl);

    const onClickAction = () => {
        removeChatMessage({
            uuid,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    const touchStartTime = useRef(0);

    const handleTouchStart = () => {
        if (!isMobile) return;
        touchStartTime.current = Date.now();
    };

    const handleTouchEnd = (event: TouchEvent<HTMLElement>) => {
        if (!isMobile) return;
        const touchDuration = Date.now() - touchStartTime.current;

        if (touchDuration > 500 && touchStartTime) {
            console.log('handleTouchEnd');
            const newAncorEl = event.currentTarget;

            setTimeout(() => {
                console.log('setAnchorEl: ', newAncorEl);

                setAnchorEl(newAncorEl);
            }, 10);
        }
    };

    const handleTouchMove = () => {
        if (!isMobile) return;
        touchStartTime.current = 0;
    };

    console.log('AnchorEl', anchorEl);

    return (
        <>
            {isCurrentUserSender && !isSystemMessage && !isMobile && (
                <IconButton
                    sx={{
                        ml: 1,
                        visibility: isButtonShow ? 'visible' : 'hidden',
                    }}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                    onClick={() => setIsUpdateOpened(true)}
                >
                    <EditSvgIcon />
                    <Typography sx={{ml: 1}}>{t('common.buttons.update')}</Typography>
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                    onClick={() => setIsOpened(true)}
                >
                    <DeleteSvgIcon />
                    <Typography sx={{ml: 1}}>{t('common.buttons.delete')}</Typography>
                </MenuItem>
            </Menu>
            <ModalWrapper
                isShowModal={isUpdateOpened}
                toggleModal={toggleIsUpdateOpened}
                title={t('requester.chat.updateChatMessage')}
            >
                <UpdateChatMessageForm
                    item={item}
                    closeForm={() => setIsUpdateOpened(false)}
                    userId={userId}
                    isMessageChangingPossible={isMessageChangingPossible}
                />
            </ModalWrapper>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                isShowModal={isOpened}
                onClickAction={onClickAction}
                description={isMessageChangingPossible ? '' : t('requester.chat.chatMessageRemovingImpossible')}
                deletingRestrict={!isMessageChangingPossible}
            />
            <Box
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
                sx={{
                    ...wrapperSx,
                }}
            >
                {children}
            </Box>
        </>
    );
};

export default ChatMessageBlockOptions;
