import {FieldOptionType} from 'appRedux/actions/forms/types';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

export const getOptionsList = (fieldOptions: FieldOptionType[], selectedOptions: string): string => {
    const results: string[] = [];
    const selectedOptionsArray = selectedOptions.split(OPTIONS_SEPARATOR);
    fieldOptions.forEach(option => {
        if (selectedOptionsArray.includes(String(option.id))) {
            results.push(option.title);
        }
    });
    return results.join(', ');
};
