import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import HasNotApprovedStatusChangeRequestsButton from 'components/HasNotApprovedStatusChangeRequestsButton/HasNotApprovedStatusChangeRequestsButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {DATE_TIME_LABEL_FORMAT} from 'config/index';

interface DeletingReasonButton {
    cancelReason: string;
    canceledAt: string;
}

const DeletingReasonButton: FC<DeletingReasonButton> = ({canceledAt, cancelReason}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <HasNotApprovedStatusChangeRequestsButton
                title={t('orguser.requesterCase.requesterCaseCanceled')}
                icon={DeleteSvgIcon}
                onClickAction={toggleModal}
                isRequesterPage
            />
            <ModalWrapper
                isShowModal={isOpened}
                toggleModal={toggleModal}
                title={t('orguser.requesterCase.notCompletedTasks')}
            >
                <Box sx={{mb: 2}}>
                    <Typography sx={{fontWeight: 700, mb: 1}}>{`${t(
                        'orguser.requesterCase.cancelReason',
                    )}:`}</Typography>
                    <Typography>{cancelReason}</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('orguser.requesterCase.canceledAt')}:`}</Typography>
                    <Typography>{moment(canceledAt).format(DATE_TIME_LABEL_FORMAT)}</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.buttons.close')}
                        onClick={() => setIsOpened(false)}
                    />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default DeletingReasonButton;
