import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';
import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';

import CustomAttributeOptionItem from 'components/Forms/CustomAttributes/CustomAttributeOptionItem';
import CreateCustomAttributeOptionForm from 'components/Forms/CustomAttributes/CreateCustomAttributeOptionForm';

import {theme} from 'config/theme';

interface CustomAttributeOptionsListType {
    item: CustomAttributesItemTypes;
}

const CustomAttributeOptionsList: FC<CustomAttributeOptionsListType> = ({item}) => {
    const [t] = useTranslation();

    const [showOptionForm, setShowOptionForm] = useState<boolean>(false);

    const toggleShowOptionForm = () => {
        setShowOptionForm(previous => !previous);
    };

    return (
        <Box sx={{mt: 2, mb: 1}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                }}
            >
                <Typography sx={{fontWeight: 700, mb: 1}}>{`${t(
                    'superadmin.customAttributes.optionsList',
                )}:`}</Typography>
                <IconButton
                    sx={{
                        backgroundColor: theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: theme.palette.info.main,
                        },
                    }}
                    onClick={toggleShowOptionForm}
                >
                    {showOptionForm ? <CloseSvgIcon isActive /> : <PlusSvgIcon isActive />}
                </IconButton>
            </Box>
            {showOptionForm && (
                <Box
                    sx={{
                        backgroundColor: theme.palette.warning.contrastText,
                        borderRadius: 3,
                        p: 1,
                        pl: 2,
                        pt: 0,
                        mt: 1,
                        mb: 1,
                    }}
                >
                    <CreateCustomAttributeOptionForm item={item} closeModal={() => setShowOptionForm(false)} />
                </Box>
            )}
            {item.options.map(option => {
                return (
                    <CustomAttributeOptionItem
                        key={`option-${option.uuid}`}
                        item={option}
                        options={item.options}
                        showOptionForm={showOptionForm}
                        closeCreateForm={() => setShowOptionForm(false)}
                    />
                );
            })}
        </Box>
    );
};

export default CustomAttributeOptionsList;
