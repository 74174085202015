import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {theme} from 'config/theme';

interface OrganizationAdminFormTabType {
    title: string;
    tabNumber: number;
    currentTab: number;
    setCurrentTab: (value: number) => void;
}

const OrganizationAdminFormTab: FC<OrganizationAdminFormTabType> = ({title, tabNumber, currentTab, setCurrentTab}) => {
    const isActive = currentTab === tabNumber;
    return (
        <Box
            sx={{
                backgroundColor: isActive ? theme.palette.info.main : theme.palette.warning.light,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                cursor: isActive ? 'initial' : 'pointer',
                mr: 1,
                pl: 2,
                pr: 2,
                pt: 1,
                pb: 1,
            }}
            onClick={() => setCurrentTab(tabNumber)}
        >
            <Typography
                sx={{
                    fontWeight: 700,
                    color: isActive ? theme.palette.background.default : theme.palette.info.main,
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default OrganizationAdminFormTab;
