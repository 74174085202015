import React, {FC, ReactNode, RefObject} from 'react';
import {useSelector} from 'react-redux';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import ElementDescription from 'pages/client/form/partials/ElementDescription';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

interface FormFieldWrapperType {
    formId: number;
    children: ReactNode;
    field: FormFieldTypes;
    wrapperRef: RefObject<HTMLDivElement | undefined>;
    isPopup?: boolean;
    hasDivider?: boolean;
    previewMode?: boolean;
    'data-id'?: string;
}

const FormFieldWrapper: FC<FormFieldWrapperType> = ({
    wrapperRef,
    formId,
    field,
    previewMode,
    children,
    hasDivider,
    'data-id': data_id,
}) => {
    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;
    const titleKeyword = getFieldKeyword(formId, field.id, 'label');
    return (
        <Box data-id={data_id} ref={wrapperRef}>
            <FormControl fullWidth sx={{mt: 1, mb: hasDivider ? 2 : 3}}>
                <Typography sx={{fontWeight: 700, fontSize: 16}}>
                    {`${getFormTranslatedLabel(translations, titleKeyword, field.label)} ${
                        field.isRequired ? '*' : ''
                    }`}
                </Typography>
                <ElementDescription
                    keyword={getFieldKeyword(formId, field.id, 'description')}
                    description={field.description}
                    previewMode={previewMode}
                />
                {children}
            </FormControl>
            {hasDivider && <Divider sx={{mt: 2, mb: 2}} />}
        </Box>
    );
};

export default FormFieldWrapper;
