import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateCustomAttributeForm from 'components/Forms/CustomAttributes/UpdateCustomAttributeForm';

interface EditAttributeButtonType {
    item: CustomAttributesItemTypes;
}

const EditAttributeButton: FC<EditAttributeButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <IconButton
                disabled={Boolean(item.parentId)}
                title={t('superadmin.customAttributes.updateCustomAttribute')}
                onClick={() => setIsOpened(true)}
            >
                <EditSvgIcon isDisabled={Boolean(item.parentId)} />
            </IconButton>
            <ModalWrapper
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                title={t('superadmin.customAttributes.updateCustomAttribute')}
            >
                <UpdateCustomAttributeForm item={item} closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default EditAttributeButton;
