import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import TasksAddSvgIcon from 'assets/icons/buttons/TasksAddSvgIcon';
import TasksCompletedSvgIcon from 'assets/icons/buttons/TasksCompletedSvgIcon';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import WorkflowTasksList from 'pages/admin/updateWorkflow/partials/tasks/WorkflowTasksList';
import TasksFromSwitcher from 'pages/admin/updateWorkflow/partials/tasks/TasksFromSwitcher';
import {checkStatusHasNotCompletedTasks} from 'pages/admin/updateWorkflow/partials/tasks/helper';
import WorkflowActionBadge from 'pages/admin/updateWorkflow/partials/WorkflowActionBadge';

interface WorkflowStatusTasksButtonType {
    item: WorkflowStatusItemTypes;
}

const WorkflowStatusTasksButton: FC<WorkflowStatusTasksButtonType> = ({item}) => {
    const [t] = useTranslation();

    const {tasks} = item;

    const [isShowForm, setIsShowForm] = useState<boolean>(false);
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const hasNotCompletedTasks = checkStatusHasNotCompletedTasks(tasks);
    const badgeContent = item.tasks.length;

    return (
        <>
            <IconButton
                title={t(
                    hasNotCompletedTasks
                        ? 'orguser.workflows.rules.hasNotCompletedTasks'
                        : 'orguser.workflows.rules.workflowStatusTasks',
                )}
                onClick={toggleIsOpened}
            >
                <WorkflowActionBadge error={hasNotCompletedTasks} badgeContent={badgeContent}>
                    {tasks.length > 0 ? <TasksCompletedSvgIcon /> : <TasksAddSvgIcon />}
                </WorkflowActionBadge>
            </IconButton>
            <ModalWrapper
                isShowModal={isOpened}
                isWideModal
                title={`${item.title}: ${t('orguser.workflows.tasks.setWorkflowStatusTasks')}`}
                toggleModal={toggleIsOpened}
            >
                <TasksFromSwitcher item={item} isShowForm={isShowForm} setIsShowForm={setIsShowForm} />
                {!isShowForm && <WorkflowTasksList item={item} />}
            </ModalWrapper>
        </>
    );
};

export default WorkflowStatusTasksButton;
