import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import {
    OrganizationAdminListTypes,
    OrganizationAdminCreateTypes,
    UserHasRolesTypes,
} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';
import {ORGANIZATION_ADMIN_UPDATE} from 'appRedux/actions/organizationAdmin';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import OrganizationAdminForm from 'components/Forms/OrganizationAdminForm/OrganizationAdminForm';
import OrganizationAdminTabsSwitcher from 'components/OrganizationAdminsList/OrganizationAdminTabsSwitcher';
import {TAB_GENERAL_INFO, TAB_CUSTOM_ATTRIBUTES} from 'components/OrganizationAdminsList/helper';
import OrganizationAdminCustomAttributes from 'components/OrganizationAdminsList/OrganizationAdminCustomAttributes';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {theme} from 'config/theme';

interface EditOrganizationAdminType {
    item: OrganizationAdminListTypes;
}

const EditOrganizationAdmin: FC<EditOrganizationAdminType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {isUserEditEnabled} = useContext(PermissionContext);
    const {showAlert} = useContext(AlertContext);

    const [userHasRoles, setUserHasRoles] = useState<UserHasRolesTypes[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<number>(TAB_GENERAL_INFO);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        id,
        admin: {firstName, lastName, email, customAttributes},
    } = item;

    return (
        <>
            <IconButton
                title={t('common.buttons.update')}
                disabled={isLoading || !isUserEditEnabled}
                sx={{mr: 1}}
                onClick={toggleModal}
            >
                <EditSvgIcon isDisabled={isLoading || !isUserEditEnabled} />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                toggleModal={toggleModal}
                title={t('superadmin.users.updateOrganizationUser')}
                isWideModal
            >
                <OrganizationAdminTabsSwitcher currentTab={currentTab} setCurrentTab={setCurrentTab} />
                <Box
                    sx={{
                        border: `2px solid ${theme.palette.info.main}`,
                        borderRadius: 4,
                        p: 2,
                    }}
                >
                    {currentTab === TAB_GENERAL_INFO && (
                        <OrganizationAdminForm
                            item={item}
                            initialValues={{
                                firstName,
                                lastName,
                                email,
                            }}
                            userHasRoles={userHasRoles}
                            setUserHasRoles={setUserHasRoles}
                            onSubmitClicked={(values: OrganizationAdminCreateTypes) => {
                                onSubmitClicked({
                                    ...values,
                                    userHasRoles,
                                    id,
                                    setErrors: values.setErrors,
                                    callback: () => setShowModal(false),
                                    showAlert,
                                });
                            }}
                        />
                    )}
                    {currentTab === TAB_CUSTOM_ATTRIBUTES && (
                        <OrganizationAdminCustomAttributes organizationAdminId={id} userAttributes={customAttributes} />
                    )}
                </Box>
            </ModalWrapper>
        </>
    );
};

export default EditOrganizationAdmin;
