import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {OrganizationLanguageTypes} from 'appRedux/actions/organizationLanguage/types';

export interface LanguageSelectorItemTypes {
    value: number;
    name: string;
}

export const getLanguagesList = (
    languageList: LanguageItemTypes[],
    organizationLanguages: OrganizationLanguageTypes[],
    isSuperAdminPage: boolean,
): LanguageSelectorItemTypes[] => {
    const results: LanguageSelectorItemTypes[] = [];
    if (isSuperAdminPage) {
        languageList.forEach(item => {
            results.push({
                value: item.id,
                name: item.lang,
            });
        });
    } else {
        organizationLanguages.forEach(item => {
            results.push({
                value: item.languageId,
                name: item.languageTitle,
            });
        });
    }
    return results;
};
