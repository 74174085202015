import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {CustomAttributesFormTypes, CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {
    CustomAttributeTypes,
    customAttributeTypes,
    CUSTOM_ATTRIBUTE_TYPE_DROPDOWN,
} from 'components/Forms/CustomAttributes/helper';
import CustomAttributeOptionsList from 'components/Forms/CustomAttributes/CustomAttributeOptionsList';
import {validationSchema} from 'components/Forms/CustomAttributes/validation';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';

interface CustomAttributeFormType {
    isCreate?: boolean;
    showOptionsList: boolean;
    item?: CustomAttributesItemTypes;
    initialValues: CustomAttributesFormTypes;
    onSubmitClicked: (values: CustomAttributesFormTypes) => void;
}

const CustomAttributeForm: FC<CustomAttributeFormType> = ({
    isCreate,
    showOptionsList,
    initialValues,
    item,
    onSubmitClicked,
}) => {
    const [t] = useTranslation();
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<CustomAttributesFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('superadmin.customAttributes.title')}
                            component={FormikTextInput}
                        />
                        <MdxEditorSmallField
                            name="description"
                            label={t('superadmin.customAttributes.description')}
                            value={initialValues.description ? initialValues.description : ''}
                            customButtons={[]}
                        />
                        <FormControl sx={{mt: 1}} required fullWidth>
                            <InputLabel>{t('superadmin.customAttributes.type')}</InputLabel>
                            <Select
                                label={t('superadmin.customAttributes.type')}
                                name={'type'}
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                disabled={!isCreate}
                                fullWidth
                            >
                                {customAttributeTypes &&
                                    customAttributeTypes.map((item: CustomAttributeTypes, index: number) => {
                                        const {value, label} = item;
                                        return (
                                            <MenuItem key={`attribute-${index}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        {showOptionsList && item && <CustomAttributeOptionsList item={item} />}
                        <Box sx={{mt: 2}}>
                            <Field
                                type="checkbox"
                                name="isOnlyOrganizationUser"
                                label={t('superadmin.customAttributes.isOnlyOrganizationUser')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </Box>
                        <Box sx={{mt: 2}}>
                            <Field
                                type="checkbox"
                                name="isManaged"
                                label={t('superadmin.customAttributes.isManaged')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit
                                title={
                                    isCreate && formik.values.type === CUSTOM_ATTRIBUTE_TYPE_DROPDOWN
                                        ? 'common.buttons.saveAndAddOptions'
                                        : 'common.buttons.saveButton'
                                }
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CustomAttributeForm;
