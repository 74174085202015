import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {UPDATE_CUSTOM_ATTRIBUTE_OPTION} from 'appRedux/actions/customAttributes';
import {CustomAttributeOptionFormTypes, CustomAttributeOptionTypes} from 'appRedux/actions/customAttributes/types';

import {AlertContext} from 'contexts/alert/context';

import CustomAttributeOptionForm from 'components/Forms/CustomAttributes/CustomAttributeOptionForm';

interface CreateCustomAttributeFormType {
    item: CustomAttributeOptionTypes;
    options: CustomAttributeOptionTypes[];
    closeModal: () => void;
}

const UpdateCustomAttributeOptionForm: FC<CreateCustomAttributeFormType> = ({item, options, closeModal}) => {
    const dispatch = useDispatch();

    const {uuid, title} = item;

    const {showAlert} = useContext(AlertContext);

    const updateCustomAttribute = useCallback(
        data => dispatch({type: UPDATE_CUSTOM_ATTRIBUTE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: CustomAttributeOptionFormTypes) => {
        updateCustomAttribute({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <CustomAttributeOptionForm
            initialValues={{
                title,
            }}
            onSubmitClicked={onSubmitClicked}
            options={options}
            closeForm={closeModal}
        />
    );
};

export default UpdateCustomAttributeOptionForm;
