import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import CheckSvgIcon from 'assets/icons/buttons/CheckSvgIcon';
import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {CustomAttributeOptionFormTypes, CustomAttributeOptionTypes} from 'appRedux/actions/customAttributes/types';

import {validationOptionSchema} from 'components/Forms/CustomAttributes/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

import {theme} from 'config/theme';

interface ComponentType {
    initialValues: CustomAttributeOptionFormTypes;
    options: CustomAttributeOptionTypes[];
    onSubmitClicked: (values: CustomAttributeOptionFormTypes) => void;
    isCreate?: boolean;
    closeForm?: () => void;
}

const CustomAttributeOptionForm: FC<ComponentType> = ({
    initialValues,
    options,
    onSubmitClicked,
    isCreate,
    closeForm,
}) => {
    const [t] = useTranslation();
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={() => validationOptionSchema(options)}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<CustomAttributeOptionFormTypes>) => {
                return (
                    <Form>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 1,
                                mb: 1,
                            }}
                        >
                            <Box sx={{width: isCreate ? '90%' : '85%'}}>
                                <Field
                                    required
                                    name="title"
                                    placeholder={t('superadmin.customAttributes.title')}
                                    component={FormikTextInput}
                                />
                            </Box>
                            <IconButton
                                sx={{
                                    backgroundColor: theme.palette.warning.contrastText,
                                    ml: 2,
                                    mt: isCreate ? 1 : 'initial',
                                    '&:hover': {
                                        backgroundColor: theme.palette.warning.contrastText,
                                    },
                                }}
                                onClick={() => formik.submitForm()}
                            >
                                <CheckSvgIcon />
                            </IconButton>
                            {!isCreate && (
                                <IconButton
                                    sx={{
                                        backgroundColor: theme.palette.warning.contrastText,
                                        ml: 1,
                                        '&:hover': {
                                            backgroundColor: theme.palette.warning.contrastText,
                                        },
                                    }}
                                    onClick={() => {
                                        closeForm && closeForm();
                                    }}
                                >
                                    <CloseSvgIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CustomAttributeOptionForm;
