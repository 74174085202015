import React, {FC, ReactNode} from 'react';

import Badge from '@mui/material/Badge';

interface WorkflowActionBadgeType {
    badgeContent: string | number;
    children: ReactNode;
    error?: boolean;
}

const WorkflowActionBadge: FC<WorkflowActionBadgeType> = ({badgeContent, children, error}) => {
    return (
        <Badge
            color={error ? 'error' : 'success'}
            invisible={!error && !badgeContent}
            badgeContent={badgeContent}
            max={9}
            sx={{
                '.MuiBadge-badge': {
                    minWidth: 18,
                    height: 16,
                    top: -2,
                    right: -1,
                    padding: '0 4px',
                    fontSize: '0.7rem',
                    fontWeight: 600,
                },
            }}
        >
            {children}
        </Badge>
    );
};

export default WorkflowActionBadge;
