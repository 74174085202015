import {CustomAttributeTranslationTypes} from 'appRedux/actions/customAttributes/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';

export const getLanguagesWithBadgeIdsArray = (
    languageList: LanguageItemTypes[],
    translations: CustomAttributeTranslationTypes[],
    requiredTranslationsNumber: number,
): number[] => {
    const results: number[] = [];
    languageList.forEach(item => {
        const translationsByLanguage = translations.filter(translation => translation.language === item.id);
        if (translationsByLanguage.length < requiredTranslationsNumber) {
            results.push(item.id);
        }
    });
    return results;
};
