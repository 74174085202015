import {useSearchParams} from 'react-router-dom';

import {CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';
import {FormItemBasic, FormTranslationItemTypes, FormAllowListTypes} from 'appRedux/actions/forms/types';
import {
    OrganizationItemBasic,
    OrganizationTemplateItemTypes,
    OrganizationPrivacyPolicyItemTypes,
    OrganizationPrivacyPolicyVersionItemTypes,
    OrganizationPdfTemplatesTypes,
} from 'appRedux/actions/organization/types';
import {OrganizationIntegrationTokenTypes} from 'appRedux/actions/organizationIntegrationTokens/types';
import {RequesterItemBasic, RequesterCaseResponseTypes, AgentItemBasic} from 'appRedux/actions/admin/types';
import {InviteTypes} from 'appRedux/actions/invites/types';
import {PublicKeyOrganizationTypes, CaseAccessRequestTypes} from 'appRedux/actions/crypto/types';
import {GroupItemTypes} from 'appRedux/actions/groups/types';
import {ChatContactTypes} from 'appRedux/actions/requestChat/types';
import {RuleTypes} from 'appRedux/actions/rules/types';
import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';
import {GeneralTemplateTypes} from 'appRedux/actions/generalTemplates/types';
import {RoleTypes} from 'appRedux/actions/roles/types';
import {BoardItemTypes} from 'appRedux/actions/workflow/types';
import {OrganizationUserListTypes} from 'appRedux/actions/organizationAdmin/types';
import {LoginAttemptTypes, DraftRemoveItemTypes} from 'appRedux/actions/superadmin/types';
import {
    ResourceFieldItemTypes,
    ResourceFieldBudgetItemTypes,
    ResourceFieldSlotItemTypes,
    ResourceFieldCalendarItemTypes,
    RequesterCaseRelatedWithSlotItems,
    RequesterCaseRelatedWithBudgetItems,
} from 'appRedux/actions/resourceFields/types';

import {TableFilterTypes} from 'components/AdminScreenComponents/types';

import {FormTranslationType} from 'helpers/translationsHelper';

import {DEFAULT_FILTER_OPTION} from 'config/index';

export type ItemsListTypes =
    | OrganizationItemBasic[]
    | OrganizationPrivacyPolicyItemTypes[]
    | OrganizationPrivacyPolicyVersionItemTypes[]
    | OrganizationIntegrationTokenTypes[]
    | FormItemBasic[]
    | GroupItemTypes[]
    | RequesterItemBasic[]
    | FormTranslationType[]
    | AgentItemBasic[]
    | RequesterCaseResponseTypes[]
    | FormTranslationItemTypes[]
    | LoginAttemptTypes[]
    | RuleTypes[]
    | RequesterCaseActivitiesTypes[]
    | GeneralTemplateTypes[]
    | RoleTypes[]
    | CaseAccessRequestTypes[]
    | PublicKeyOrganizationTypes[]
    | InviteTypes[]
    | BoardItemTypes[]
    | OrganizationUserListTypes[]
    | FormAllowListTypes[]
    | OrganizationTemplateItemTypes[]
    | DraftRemoveItemTypes[]
    | ResourceFieldItemTypes[]
    | ResourceFieldBudgetItemTypes[]
    | ResourceFieldSlotItemTypes[]
    | ResourceFieldCalendarItemTypes[]
    | RequesterCaseRelatedWithSlotItems[]
    | RequesterCaseRelatedWithBudgetItems[]
    | OrganizationPdfTemplatesTypes[]
    | CustomAttributesItemTypes[]
    | undefined;

export const getPagesNumber = (itemsList: ItemsListTypes, itemsPerPage: number, itemsTotalNumber?: number): number => {
    if (itemsTotalNumber && itemsPerPage > 0) {
        return Math.ceil(itemsTotalNumber / itemsPerPage);
    }
    return itemsList && itemsPerPage > 0 ? Math.ceil(itemsList.length / itemsPerPage) : 0;
};

export const getParameterFromUrl = (parameter: string | null) => {
    return parameter && parameter !== DEFAULT_FILTER_OPTION ? parameter : '';
};

export const getSearchFromUrl = (parameter: string | null) => {
    return parameter && parameter.length >= 3 ? parameter : '';
};

export const isAnyFilterSet = (filters: TableFilterTypes[]) => {
    const [searchParams] = useSearchParams();
    if (searchParams.get('search')) {
        return true;
    }
    let isFilterSet = false;

    for (const filter of filters) {
        if (filter.type === 'daterangepicker') {
            if (searchParams.get(filter?.from?.parameter || '') || searchParams.get(filter?.to?.parameter || '')) {
                isFilterSet = true;
                break;
            }
        } else {
            if (searchParams.get(filter.parameter || '')) {
                isFilterSet = true;
                break;
            }
        }
    }

    return isFilterSet;
};

export const getNotReadMessagesNumber = (contacts: ChatContactTypes[]): number => {
    let result = 0;
    for (let i = 0, n = contacts.length; i < n; i++) {
        result += contacts[i].notReadMessages;
    }
    return result;
};
