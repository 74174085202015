import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import AgentAddMultipleButton from 'components/AgentScreenComponents/_buttons/AgentAddMultipleButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateCustomAttributeForm from 'components/Forms/CustomAttributes/CreateCustomAttributeForm';
import AttachCustomAttributeToOrganizationForm from 'components/Forms/CustomAttributes/AttachCustomAttributeToOrganizationForm';

import {theme} from 'config/theme';

const AddCustomAttributeDropdown: FC = () => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showAddCustomAttribute, setShowAddCustomAttribute] = useState<boolean>(false);
    const [showAddGlobalAttribute, setShowAddGlobalAttribute] = useState<boolean>(false);

    const toggleAddCustomAttribute = () => {
        setShowAddCustomAttribute(previous => !previous);
    };

    const toggleAddGlobalAttribute = () => {
        setShowAddGlobalAttribute(previous => !previous);
    };

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentAddMultipleButton
                title={t('superadmin.customAttributes.addCustomField')}
                onClick={handleClick}
                open={open}
            />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        minWidth: 250,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            pColumning: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem onClick={toggleAddCustomAttribute}>
                    <Typography>{t('superadmin.customAttributes.addCustomField')}</Typography>
                </MenuItem>
                <MenuItem onClick={toggleAddGlobalAttribute}>
                    <Typography>{t('superadmin.customAttributes.addGlobalField')}</Typography>
                </MenuItem>
            </Menu>
            <ModalWrapper
                isShowModal={showAddCustomAttribute}
                toggleModal={toggleAddCustomAttribute}
                title={t('superadmin.customAttributes.addCustomField')}
            >
                <CreateCustomAttributeForm closeModal={() => setShowAddCustomAttribute(false)} />
            </ModalWrapper>
            <ModalWrapper
                isShowModal={showAddGlobalAttribute}
                toggleModal={toggleAddGlobalAttribute}
                title={t('superadmin.customAttributes.addGlobalField')}
            >
                <AttachCustomAttributeToOrganizationForm closeModal={() => setShowAddGlobalAttribute(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default AddCustomAttributeDropdown;
