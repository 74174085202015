export function makeAbbreviation(str: string) {
    if (!str || typeof str !== 'string') return '';

    const fillerWordsRegex = /^[a-z]{1,3}$/; // remove filling words
    const words = str.trim().split(/\s+/);

    console.log('words', words);

    if (words.length === 1) {
        // Handle single long word: evenly spaced characters
        const word = words[0];
        const length = word.length;
        const steps = Math.max(2, Math.ceil(length / 3)); // At least 3 characters
        let abbreviation = word[0].toUpperCase();
        for (let i = steps; i < length; i += steps) {
            abbreviation += word[i].toUpperCase();
        }
        return abbreviation;
    }

    // Handle multi-word phrases
    return words
        .filter(word => !fillerWordsRegex.test(word)) // Exclude filler words
        .map(word => {
            if (word === word.toUpperCase() && word.length > 1) {
                return word; // Use existing abbreviation if the word is fully uppercase
            }
            return word.charAt(0).toUpperCase(); // Otherwise, take the first letter
        })
        .join('');
}
