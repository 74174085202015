import React, {FC, ReactNode, RefObject, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import ElementDescription from 'pages/client/form/partials/ElementDescription';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

interface FormSelectorWrapperType {
    formId: number;
    field: FormFieldTypes;
    children: ReactNode;
    wrapperRef: RefObject<HTMLDivElement | undefined>;
    'data-id'?: string;
    previewMode?: boolean;
    hasDivider?: boolean;
}

const FormSelectorWrapper: FC<FormSelectorWrapperType> = ({
    field,
    wrapperRef,
    formId,
    previewMode,
    children,
    hasDivider,
    'data-id': dataId,
}) => {
    const [t] = useTranslation();

    const {errorField} = useContext(ClientFormContext);

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {label, isRequired, id} = field;

    const keywordLabel = getFieldKeyword(formId, field.id, 'label');

    const isError = id === errorField;

    return (
        <Box data-id={dataId} ref={wrapperRef}>
            <FormControl fullWidth error={isError} sx={{mt: 1, mb: 1}}>
                <Typography sx={{fontWeight: 700, fontSize: 16}}>
                    {`${previewMode ? label : getFormTranslatedLabel(translations, keywordLabel, label)} ${
                        isRequired ? '*' : ''
                    }`}
                </Typography>
                <ElementDescription
                    keyword={getFieldKeyword(formId, field.id, 'description')}
                    description={field.description}
                    previewMode={previewMode}
                />
                {children}
                {isError && <FormHelperText>{t('messages.error.errorIsRequired')}</FormHelperText>}
            </FormControl>
            {hasDivider && <Divider sx={{mt: 2, mb: 2}} />}
        </Box>
    );
};

export default FormSelectorWrapper;
