import React, {FC, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {CustomAttributeOptionTypes} from 'appRedux/actions/customAttributes/types';

import UpdateCustomAttributeOptionForm from 'components/Forms/CustomAttributes/UpdateCustomAttributeOptionForm';
import DeleteCustomAttributeOptionButton from 'components/Forms/CustomAttributes/DeleteCustomAttributeOptionButton';
import EmptyArea from 'components/EmptyArea/EmptyArea';

import {theme} from 'config/theme';

interface ComponentType {
    item: CustomAttributeOptionTypes;
    options: CustomAttributeOptionTypes[];
    showOptionForm: boolean;
    closeCreateForm: () => void;
}

const CustomAttributeOptionItem: FC<ComponentType> = ({item, options, showOptionForm, closeCreateForm}) => {
    const [showForm, setShowForm] = useState<boolean>(false);

    const {title} = item;

    useEffect(() => {
        if (showOptionForm) {
            setShowForm(false);
        }
    }, [showOptionForm]);

    if (showForm) {
        return (
            <UpdateCustomAttributeOptionForm
                item={item}
                options={options}
                closeModal={() => {
                    setShowForm(false);
                    closeCreateForm();
                }}
            />
        );
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                alignItems: 'center',
                backgroundColor: theme.palette.warning.contrastText,
                borderRadius: 3,
                p: 1,
                pl: 2,
                mt: 1,
                mb: 1,
            }}
        >
            <Typography>{title}</Typography>
            <EmptyArea />
            <IconButton
                onClick={() => {
                    setShowForm(true);
                    closeCreateForm();
                }}
            >
                <EditSvgIcon />
            </IconButton>
            <DeleteCustomAttributeOptionButton item={item} />
        </Box>
    );
};

export default CustomAttributeOptionItem;
