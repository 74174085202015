import React, {FC, MouseEvent} from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {ModalWrapperType} from 'components/ModalWrapper/types';

import {theme} from 'config/theme';

const ClientModalWrapper: FC<ModalWrapperType> = ({
    children,
    title,
    isWideModal,
    isShowModal,
    toggleModal,
    ...props
}) => {
    const handleClose = (event: any, reason: string) => {
        if (reason && reason == 'backdropClick') return;
        toggleModal && toggleModal();
    };

    const onModalClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <Modal open={isShowModal} onClose={handleClose} style={{overflow: 'scroll'}} onClick={onModalClick} {...props}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    p: 2,
                    boxShadow: 24,
                    width: isWideModal ? '55vw' : 565,
                    minWidth: 565,
                    minHeight: isWideModal ? '20vh' : 'initial',
                    position: 'absolute',
                    margin: '50px auto',
                    left: 0,
                    right: 0,
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 4,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: title ? 'space-between' : 'flex-end',
                        alignItems: 'flex-start',
                        mb: 2,
                    }}
                >
                    <Typography align="left" sx={{mb: 1, mt: -0.5, fontSize: 16, fontWeight: 'bold'}}>
                        {title}
                    </Typography>
                    {toggleModal && (
                        <IconButton sx={{p: 0}} onClick={toggleModal}>
                            <CancelSvgIcon />
                        </IconButton>
                    )}
                </Box>
                {children}
            </Box>
        </Modal>
    );
};

export default ClientModalWrapper;
