import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import CreateCustomAttributeForm from 'components/Forms/CustomAttributes/CreateCustomAttributeForm';

const AddCustomAttributeButton: FC = () => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentInverseButton
                title={t('superadmin.customAttributes.addCustomField')}
                onClick={toggleIsOpened}
                icon={<PlusSvgIcon />}
            />
            <ModalWrapper
                isShowModal={isOpened}
                title={t('superadmin.customAttributes.addCustomField')}
                toggleModal={toggleIsOpened}
            >
                <CreateCustomAttributeForm closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default AddCustomAttributeButton;
