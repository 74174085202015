import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {DELETE_CUSTOM_ATTRIBUTE_OPTION} from 'appRedux/actions/customAttributes';
import {CustomAttributeOptionTypes} from 'appRedux/actions/customAttributes/types';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface DeleteCustomAttributeOptionButtonType {
    item: CustomAttributeOptionTypes;
}

const DeleteCustomAttributeOptionButton: FC<DeleteCustomAttributeOptionButtonType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const {uuid} = item;

    const deleteCustomAttributeOption = useCallback(
        data => dispatch({type: DELETE_CUSTOM_ATTRIBUTE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteCustomAttributeOption({
            uuid,
            callback: toggleModal,
            showAlert,
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={false}
            isShowModal={isShowModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteCustomAttributeOptionButton;
