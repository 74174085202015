import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';
import {ORGANIZATION_ADMIN_UPDATE_CUSTOM_ATTRIBUTE} from 'appRedux/actions/organizationAdmin';
import {
    OrganizationAdminCustomAttributeFormTypes,
    UserHasCustomAttributeTypes,
} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {
    CUSTOM_ATTRIBUTE_TYPE_TEXTAREA,
    CUSTOM_ATTRIBUTE_TYPE_DROPDOWN,
    getCustomAttributeTranslationKeyword,
    getCustomAttributeTranslatedLabel,
} from 'components/Forms/CustomAttributes/helper';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import {validateCustomAttribute} from 'components/Forms/OrganizationAdminForm/validation';
import OrganizationAdminCustomAttributeDropdownForm from 'components/Forms/OrganizationAdminForm/OrganizationAdminCustomAttributeDropdownForm';
import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import {DEFAULT_VALUE} from 'config/index';

interface OrgAdminCustomAttributeFormType {
    organizationAdminId: number;
    userAttribute?: UserHasCustomAttributeTypes | null;
    customAttribute: CustomAttributesItemTypes;
}

const OrganizationAdminCustomAttributeForm: FC<OrgAdminCustomAttributeFormType> = ({
    organizationAdminId,
    customAttribute,
    userAttribute,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, title, type, parentId} = customAttribute;

    const updateOrganizationAdminCustomAttribute = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_UPDATE_CUSTOM_ATTRIBUTE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: OrganizationAdminCustomAttributeFormTypes) => {
        updateOrganizationAdminCustomAttribute({
            id: organizationAdminId,
            attribute: id,
            ...values,
            showAlert,
        });
    };

    const titleKeyword = parentId
        ? getCustomAttributeTranslationKeyword(parentId, 'title')
        : getCustomAttributeTranslationKeyword(id, 'title');
    const descriptionKeyword = parentId
        ? getCustomAttributeTranslationKeyword(parentId, 'description')
        : getCustomAttributeTranslationKeyword(id, 'description');

    const translatedLabel = profile
        ? getCustomAttributeTranslatedLabel(customAttribute, titleKeyword, profile.languageId, title)
        : title;
    const translatedDescription = profile
        ? getCustomAttributeTranslatedLabel(customAttribute, descriptionKeyword, profile.languageId, title)
        : title;

    if (customAttribute.type === CUSTOM_ATTRIBUTE_TYPE_DROPDOWN) {
        return (
            <OrganizationAdminCustomAttributeDropdownForm
                initialValues={{
                    value: DEFAULT_VALUE,
                    option: userAttribute ? userAttribute.option : null,
                }}
                onSubmitClicked={onSubmitClicked}
                translatedLabel={translatedLabel}
                translatedDescription={translatedDescription}
                customAttribute={customAttribute}
            />
        );
    }
    return (
        <Box sx={{mb: 1}}>
            <Formik
                initialValues={{
                    value: userAttribute ? userAttribute.value : '',
                    option: null,
                }}
                validationSchema={validateCustomAttribute}
                onSubmit={onSubmitClicked}
            >
                {(formik: FormikProps<any>) => {
                    return (
                        <Form>
                            {type === CUSTOM_ATTRIBUTE_TYPE_TEXTAREA ? (
                                <MdxEditorSmallField
                                    name="value"
                                    label={translatedLabel}
                                    value={userAttribute ? userAttribute.value : ''}
                                    customButtons={[]}
                                    customOnBlur={formik.submitForm}
                                    description={translatedDescription}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <Box sx={{width: '95%'}}>
                                        <Field
                                            name="value"
                                            label={translatedLabel}
                                            type="text"
                                            component={FormikTextInput}
                                            onBlur={formik.submitForm}
                                        />
                                    </Box>
                                    {translatedDescription && (
                                        <DescriptionPopover text={translatedDescription ? translatedDescription : ''} />
                                    )}
                                </Box>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default OrganizationAdminCustomAttributeForm;
