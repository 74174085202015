import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {get} from 'lodash';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import {RESEND_EMAIL} from 'appRedux/actions/profile';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {theme} from 'config/theme';

interface FileUploadThumbButtonType {
    loadFileClick: () => void;
    disabled?: boolean;
}

const FileUploadThumbButton: FC<FileUploadThumbButtonType> = ({loadFileClick, disabled}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isUserVerified = get(profile, ['isVerify'], false);

    const [showVerification, setShowVerification] = useState<boolean>(false);

    const toggleVerificationModal = () => {
        setShowVerification(previous => !previous);
    };

    const resendEmailAction = useCallback(data => dispatch({type: RESEND_EMAIL.REQUEST, payload: data}), [dispatch]);

    const onSendAgainClicked = () => {
        resendEmailAction({
            showAlert,
            callback: () => {
                setShowVerification(false);
            },
        });
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 1,
                    overflow: 'hidden',
                    cursor: 'pointer',
                    position: 'relative',
                    height: '150px',
                    width: '150px',
                    border: `2px dashed ${theme.palette.info.dark}`,
                    color: theme.palette.info.light,
                    opacity: disabled ? 0.7 : 1,
                }}
                onClick={disabled ? undefined : isUserVerified ? loadFileClick : toggleVerificationModal}
            >
                <AddRoundedIcon sx={{width: '70px', height: '70px'}} />
            </Box>

            {showVerification && (
                <ModalWrapper
                    title={t('messages.error.fileAttachingDenied')}
                    isShowModal={showVerification}
                    toggleModal={toggleVerificationModal}
                >
                    <Typography sx={{color: theme.palette.info.main, mb: 2}}>
                        {`${t('messages.warning.verifyEmailToAllowUploads')}.`}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <AgentSaveButton
                            isSubmit={false}
                            title={t('common.buttons.sendItAgain')}
                            onClick={onSendAgainClicked}
                        />
                    </Box>
                </ModalWrapper>
            )}
        </>
    );
};

export default FileUploadThumbButton;
