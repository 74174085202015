import React, {FC} from 'react';

import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import {styled} from '@mui/material/styles';
import ToggleButtonGroup, {toggleButtonGroupClasses} from '@mui/material/ToggleButtonGroup';
import {theme} from 'config/theme';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: '16px',
    position: 'relative',
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        zIndex: 1,
        border: 0,
        margin: '2px',
        borderRadius: '50%',
        padding: '1px',
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
        svg: {
            width: '24px',
            height: '24px',
        },
        '&.Mui-selected, &:hover, &.Mui-selected:hover': {
            backgroundColor: 'transparent',
        },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
        marginLeft: '-2px',
        borderLeft: 0,
    },
    ['span']: {
        zIndex: 0,
        height: '26px',
        width: '26px',
        lineHeight: '26px',
        borderRadius: '50%',
        display: 'block',
        position: 'absolute',
        top: '2px',
        transition: 'all 0.3s ease-in-out',
    },
}));

interface AgentMultiWayToggleType<T> {
    value: T;
    options: {value: T; Icon: FC; backgroundColor?: string; disabled?: boolean}[];
    handleChange?: (event: React.MouseEvent<HTMLElement>, newValue: T) => void;
}

const AgentMultiWayToggle = <T extends string>({value, options, handleChange}: AgentMultiWayToggleType<T>) => {
    const activeOptionIndex = options.findIndex(opt => opt.value === value);
    const activeOption = options.find(opt => opt.value === value);

    return (
        <StyledToggleButtonGroup
            value={value}
            exclusive
            onChange={handleChange}
            aria-label="Multi way toggle"
            size="small"
        >
            {options.map(({value, Icon, disabled}) => (
                <ToggleButton
                    value={value}
                    aria-label={`${value}-area`}
                    key={`${value}-key`}
                    disableRipple
                    disabled={disabled}
                >
                    <Icon />
                </ToggleButton>
            ))}
            <Box
                component="span"
                sx={{
                    left: 26 * activeOptionIndex + 2 + 'px',
                    backgroundColor: activeOption?.backgroundColor || theme.palette.secondary.contrastText,
                }}
            ></Box>
        </StyledToggleButtonGroup>
    );
};

export default AgentMultiWayToggle;
