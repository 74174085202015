import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {TAB_GENERAL_INFO, TAB_CUSTOM_ATTRIBUTES} from 'components/OrganizationAdminsList/helper';
import OrganizationAdminFormTab from 'components/OrganizationAdminsList/OrganizationAdminFormTab';

interface OrganizationAdminTabsSwitcherType {
    currentTab: number;
    setCurrentTab: (value: number) => void;
}

const OrganizationAdminTabsSwitcher: FC<OrganizationAdminTabsSwitcherType> = ({currentTab, setCurrentTab}) => {
    const [t] = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                pl: 2,
                pr: 2,
            }}
        >
            <OrganizationAdminFormTab
                title={t('superadmin.users.tabs.generalInformation')}
                tabNumber={TAB_GENERAL_INFO}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
            <OrganizationAdminFormTab
                title={t('superadmin.users.tabs.customAttributes')}
                tabNumber={TAB_CUSTOM_ATTRIBUTES}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
        </Box>
    );
};

export default OrganizationAdminTabsSwitcher;
