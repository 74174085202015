import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {DELETE_CUSTOM_ATTRIBUTE} from 'appRedux/actions/customAttributes';
import {CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface DeleteAttributeButtonType {
    item: CustomAttributesItemTypes;
}

const DeleteAttributeButton: FC<DeleteAttributeButtonType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const {uuid} = item;

    const deleteCustomAttribute = useCallback(
        data => dispatch({type: DELETE_CUSTOM_ATTRIBUTE.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteCustomAttribute({
            uuid,
            callback: toggleModal,
            showAlert,
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={false}
            isShowModal={isShowModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteAttributeButton;
