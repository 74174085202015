import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {CREATE_CUSTOM_ATTRIBUTE} from 'appRedux/actions/customAttributes';
import {CustomAttributesFormTypes} from 'appRedux/actions/customAttributes/types';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import CustomAttributeForm from 'components/Forms/CustomAttributes/CustomAttributeForm';
import UpdateCustomAttributeForm from 'components/Forms/CustomAttributes/UpdateCustomAttributeForm';
import {
    CUSTOM_ATTRIBUTE_TYPE_TEXT_INPUT,
    CUSTOM_ATTRIBUTE_TYPE_DROPDOWN,
} from 'components/Forms/CustomAttributes/helper';

interface CreateCustomAttributeFormType {
    closeModal: () => void;
}

const CreateCustomAttributeForm: FC<CreateCustomAttributeFormType> = ({closeModal}) => {
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {isSuperAdminPage} = useContext(RouteContext);

    const [showUpdateForm, setShowUpdateForm] = useState<boolean>(false);

    const createCustomAttribute = useCallback(
        data => dispatch({type: CREATE_CUSTOM_ATTRIBUTE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        instance: {customAttributes},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = (values: CustomAttributesFormTypes) => {
        createCustomAttribute({
            ...values,
            organization: isSuperAdminPage || !myOrganization ? null : myOrganization.id,
            showAlert,
            callback: () => {
                if (values.type === CUSTOM_ATTRIBUTE_TYPE_DROPDOWN) {
                    setShowUpdateForm(true);
                } else {
                    closeModal();
                }
            },
        });
    };

    const lastCreatedItem = customAttributes.length > 0 ? customAttributes[0] : null;
    if (showUpdateForm && lastCreatedItem) {
        return <UpdateCustomAttributeForm item={lastCreatedItem} closeModal={closeModal} />;
    }
    return (
        <CustomAttributeForm
            initialValues={{
                title: '',
                description: '',
                type: CUSTOM_ATTRIBUTE_TYPE_TEXT_INPUT,
                isOnlyOrganizationUser: false,
                isManaged: false,
            }}
            onSubmitClicked={onSubmitClicked}
            showOptionsList={false}
            isCreate
        />
    );
};

export default CreateCustomAttributeForm;
