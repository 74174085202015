import React, {FC} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {CustomAttributesItemTypes, CustomAttributeOptionTypes} from 'appRedux/actions/customAttributes/types';
import {OrganizationAdminCustomAttributeFormTypes} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';

import {validateCustomAttribute} from 'components/Forms/OrganizationAdminForm/validation';
import {
    getCustomAttributeOptionTranslationKeyword,
    getCustomAttributeTranslatedLabel,
} from 'components/Forms/CustomAttributes/helper';
import DescriptionPopover from '../../DescriptionPopover/DescriptionPopover';

interface OrganizationAdminCustomAttributeDropdownFormType {
    customAttribute: CustomAttributesItemTypes;
    initialValues: OrganizationAdminCustomAttributeFormTypes;
    onSubmitClicked: (values: OrganizationAdminCustomAttributeFormTypes) => void;
    translatedLabel: string;
    translatedDescription?: string;
}

const OrganizationAdminCustomAttributeDropdownForm: FC<OrganizationAdminCustomAttributeDropdownFormType> = ({
    initialValues,
    onSubmitClicked,
    customAttribute,
    translatedLabel,
    translatedDescription,
}) => {
    const {options, parentId} = customAttribute;

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box>
            <Formik initialValues={initialValues} validationSchema={validateCustomAttribute} onSubmit={onSubmitClicked}>
                {(formik: FormikProps<any>) => {
                    return (
                        <Form>
                            <FormControl sx={{mt: 1}} required fullWidth>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <Box sx={{width: translatedDescription ? '95%' : '100%'}}>
                                        <InputLabel>{translatedLabel}</InputLabel>
                                        <Select
                                            label={translatedLabel}
                                            name={'option'}
                                            value={formik.values.option}
                                            onChange={e => {
                                                formik.handleChange(e);
                                                formik.setFieldValue('value', e.target.value);
                                                formik.submitForm();
                                            }}
                                            IconComponent={KeyboardArrowDownIcon}
                                            fullWidth
                                        >
                                            {options &&
                                                options.map((item: CustomAttributeOptionTypes, index: number) => {
                                                    const {id, title} = item;
                                                    const optionTitleKeyword = parentId
                                                        ? getCustomAttributeOptionTranslationKeyword(parentId, id)
                                                        : getCustomAttributeOptionTranslationKeyword(
                                                              customAttribute.id,
                                                              id,
                                                          );
                                                    const translatedLabel = profile
                                                        ? getCustomAttributeTranslatedLabel(
                                                              customAttribute,
                                                              optionTitleKeyword,
                                                              profile.languageId,
                                                              title,
                                                          )
                                                        : title;
                                                    return (
                                                        <MenuItem key={`attribute-${index}`} value={id}>
                                                            <Typography sx={{fontWeight: 600}}>
                                                                {translatedLabel}
                                                            </Typography>
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </Box>
                                    {translatedDescription && (
                                        <DescriptionPopover text={translatedDescription ? translatedDescription : ''} />
                                    )}
                                </Box>
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default OrganizationAdminCustomAttributeDropdownForm;
