import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';

import UserHasCustomAttributeForm from 'components/Forms/SettingsForms/UserHasCustomAttributeForm/UserHasCustomAttributeForm';

interface AdditionalUserInformationType {
    attributes: CustomAttributesItemTypes[];
}

const AdditionalUserInformation: FC<AdditionalUserInformationType> = ({attributes}) => {
    return (
        <Box>
            {attributes.map(attribute => {
                return <UserHasCustomAttributeForm key={`attribute-${attribute.uuid}`} attribute={attribute} />;
            })}
        </Box>
    );
};

export default AdditionalUserInformation;
